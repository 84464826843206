import schema from "@roda/graphql/schema.json";
import { cacheExchange } from "@urql/exchange-graphcache";
import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import { IntercomProvider } from "react-use-intercom";
import {
  createClient,
  fetchExchange
} from "urql";

import { STORAGE_KEYS } from "~/constants/storageKeys";
import { AuthProvider } from "~/contexts/AuthContext";
import { CustomiseFlywheelGoalProvider } from "~/contexts/CustomiseFlywheelGoalContext/CustomiseFlywheelGoalContext";
import { CustomiseMetricProvider } from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import { FlywheelLoaderProvider } from "~/contexts/FlywheelLoaderContext";
import { HomeRouteProvider } from "~/contexts/HomeRouteContext";
import { ImageCaptureProvider } from "~/contexts/ImageCaptureContext";
import { OnboardingProvider } from "~/contexts/OnboardingContext/OnboardingContext";
import { RodaAdminCompaniesProvider } from "~/contexts/RodaAdminCompaniesContext";
import { SideNavigationProvider } from "~/contexts/SideNavigationContext";
import { UserProvider } from "~/contexts/UserContext";
import { clearStorage } from "~/utils/clearStorage";
import { sentryInit } from "~/utils/sentry";

import { App } from "./app";
import { createStepsWithMetrics } from "./cache/updates/createStepsWithMetrics";
import { deleteUserFromCompany } from "./cache/updates/deleteUserFromCompany";
import { inviteUser } from "./cache/updates/inviteUser";
import { ClientProvider } from "./contexts/ClientContext";

import type { ClientOptions } from "urql";

const INTERCOM_APP_ID = "xhr1kaak";

sentryInit();

const makeClient = () => {
  try {
    const params = new URLSearchParams(window.location.search);
    // Get auth token from URL params
    const token = params.get("token");

    // Set token in localStorage
    if (token) {
      // clear any prev tokens
      clearStorage({ groupNames: [ "logout" ] });
      localStorage.setItem(STORAGE_KEYS.LOCAL_STORAGE.AUTH_TOKEN, token);
    }

    const clientURL = import.meta.env.VITE_GRAPHQL_URL;

    const clientConfig: ClientOptions = {
      url: clientURL,
      fetchOptions: () => {
        const token = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE.AUTH_TOKEN);

        if (!token) return {};

        return { headers: { Authorization: `Bearer ${token}` } }; // Use auth token
      },
      exchanges: [
        cacheExchange({
          schema,
          updates: {
            Mutation: {
              inviteUser,
              createStepsWithMetrics,
              deleteUserFromCompany
            }
          }
        }),
        fetchExchange
      ]
    };

    return createClient(clientConfig);
  } catch (error) {
    console.error(error);
    throw "Error creating urql client";
  }
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <IntercomProvider
      autoBoot
      appId={INTERCOM_APP_ID}
    >
      <ClientProvider makeClient={makeClient!}>
        <AuthProvider>
          <UserProvider>
            <RodaAdminCompaniesProvider>
              <HomeRouteProvider>
                <OnboardingProvider>
                  <CustomiseMetricProvider>
                    <CustomiseFlywheelGoalProvider>
                      <FlywheelLoaderProvider>
                        <SideNavigationProvider>
                          <ImageCaptureProvider>
                            <App />
                          </ImageCaptureProvider>
                        </SideNavigationProvider>
                      </FlywheelLoaderProvider>
                    </CustomiseFlywheelGoalProvider>
                  </CustomiseMetricProvider>
                </OnboardingProvider>
              </HomeRouteProvider>
            </RodaAdminCompaniesProvider>
          </UserProvider>
        </AuthProvider>
      </ClientProvider>
    </IntercomProvider>
  </React.StrictMode>
);
