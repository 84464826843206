import { useTypedMutation } from "@roda/graphql/urql";

import {
  FlywheelGoal,
  ReadOnlyUser
} from "~/fragments/index";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

export type CreateStepsWithMetricsParams = NonNullable<MutationGenqlSelection["createStepsWithMetrics"]>["__args"];

export const useCreateStepsWithMetrics = () => {
  return useTypedMutation((vars: CreateStepsWithMetricsParams) => ({
    createStepsWithMetrics: {
      __args: vars,
      id: true,
      name: true,
      companyId: true,
      deletedAt: true,
      ownerId: true,
      currency: true,
      flywheelTemplateId: true,
      timeframe: true,
      updateFrequency: true,
      setupComplete: true,
      steps: {
        id: true,
        name: true,
        ownerId: true,
        order: true,
        metrics: {
          id: true,
          name: true,
          owner: {
            id: true,
            firstName: true,
            lastName: true,
            email: true,
            avatarS3Key: true
          },
          stepId: true,
          unitName: true,
          unitType: true,
          unitTypeLabel: true,
          unitDisplay: true,
          unitDescription: true,
          unitTargetType: true,
          createdAt: true,
          cap: true,
          reportingWindowTiming: true,
          slug: true,
          targets: {
            id: true,
            metricId: true,
            target: true,
            createdBy: ReadOnlyUser,
            fromDate: true,
            toDate: true,
            isCurrent: true,
            createdAt: true,
            updatedAt: true
          },
          isCheckInDue: true,
          isHealthy: true,
          metricUpdates: {
            id: true,
            metricId: true,
            isHealthy: true,
            startDate: true,
            metricTargetId: true,
            metricTarget: {
              id: true,
              metricId: true,
              target: true,
              createdBy: ReadOnlyUser,
              fromDate: true,
              toDate: true,
              isNext: true,
              isCurrent: true,
              createdAt: true,
              updatedAt: true
            },
            notes: true,
            updatedBy: ReadOnlyUser,
            value: true,
            updatedAt: true,
            createdAt: true,
            __typename: true,
            __scalar: true
          },
          fromDate: true,
          toDate: true
        }
      },
      latestFlywheelGoal: FlywheelGoal
    }
  }));
};