import { FlywheelGoal } from "~/fragments/flywheelGoal";
import { Step } from "~/fragments/step";

import type { FlywheelGenqlSelection } from "@roda/graphql/genql";

export const Flywheel: FlywheelGenqlSelection = {
  id: true,
  name: true,
  companyId: true,
  deletedAt: true,
  ownerId: true,
  currency: true,
  flywheelTemplateId: true,
  timeframe: true,
  updateFrequency: true,
  setupComplete: true,
  steps: Step,
  latestFlywheelGoal: FlywheelGoal
};

export const ReducedFlywheel: FlywheelGenqlSelection = {
  id: true,
  name: true,
  companyId: true,
  ownerId: true,
  currency: true,
  flywheelTemplateId: true,
  deletedAt: true,
  timeframe: true,
  updateFrequency: true,
  setupComplete: true,
  steps: {
    id: true,
    name: true,
    order: true,
    ownerId: true,
    alias: true,
    metrics: {
      id: true,
      name: true,
      owner: {
        id: true,
        firstName: true,
        lastName: true,
        email: true,
        avatarS3Key: true
      },
      stepId: true,
      unitName: true,
      unitType: true,
      unitTypeLabel: true,
      unitDisplay: true,
      unitDescription: true,
      unitTargetType: true,
      cap: true,
      reportingWindowTiming: true,
      slug: true,
      isCheckInDue: true,
      isReviewDue: true,
      isHealthy: true,
      fromDate: true,
      toDate: true
    }
  },
  latestFlywheelGoal: FlywheelGoal
};
