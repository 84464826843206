import { Industry } from "~/fragments/industry";

import type { CompanyGenqlSelection } from "@roda/graphql/genql";

export const Company: CompanyGenqlSelection = {
  id: true,
  name: true,
  industryId: true,
  createdAt: true,
  updatedAt: true,
  paymentPrompt: true,
  trialExpiryAt: true,
  stripeCustomerRef: true,
  stripeStatus: true,
  stripeSubscriptionRef: true,
  completedOnboarding: true,
  industry: Industry,
  logoS3Key: true,
  logoS3Bucket: true,
  flywheels: {
    id: true,
    companyId: true,
    setupComplete: true,
    deletedAt: true,
    name: true,

    ownerId: true,
    currency: true,
    flywheelTemplateId: true,

    timeframe: true,
    updateFrequency: true,

    steps: {
      id: true,
      name: true,
      order: true,
      ownerId: true,
      alias: true
    },
    latestFlywheelGoal: {
      id: true,
      achieveBy: true,
      ownerId: true,
      createdBy: true,
      goal: true,
      cap: true,
      name: true,
      unitName: true,
      unitType: true,
      unitTypeLabel: true,
      unitDisplay: true,
      unitDescription: true,
      updateFrequency: true,
      subgoals: {
        id: true,
        endDate: true,
        createdAt: true,
        updatedAt: true,
        __typename: true,
        startDate: true,
        goal: true,
        flywheelGoalId: true,
        latestUpdate: {
          id: true,
          flywheelGoalId: true,
          startDate: true,
          value: true,
          notes: true,
          isHealthy: true,
          updatedAt: true
        },

        isHealthy: true,
        isCheckInDue: true,
        latestProgress: true
      },
      latestTotalValue: true,
      checkInSubgoal: {
        id: true,
        endDate: true,
        startDate: true,
        createdAt: true,
        updatedAt: true,
        __typename: true,
        goal: true,
        flywheelGoalId: true,
        latestUpdate: {
          id: true,
          flywheelGoalId: true,
          startDate: true,
          value: true,
          notes: true,
          isHealthy: true,
          subgoalId: true,
          updatedAt: true,
          createdAt: true,
          __typename: true
        },

        isHealthy: true,
        isCheckInDue: true,
        latestProgress: true
      },
      isHealthy: true,
      fromDate: true,
      toDate: true
    }

  }
};
