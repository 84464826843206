import { zodResolver } from "@hookform/resolvers/zod";
import {
  availableFlywheelTemplates,
  FlywheelGoalEnum,
  flywheelGoalUnits,
  FlywheelTemplateUnitTypeLabelEnum,
  UpdateFrequency
} from "@roda/shared/types";
import { removeEmojis } from "@roda/shared/utils";
import {
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import {
  useNavigate,
  useParams
} from "react-router-dom";
import { z } from "zod";

import { Button } from "~/components/Button";
import { CalloutBadge } from "~/components/CalloutBadge";
import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import { CustomiseFlywheelGoal } from "~/components/customise-flywheel-goal/CustomiseFlywheelGoal";
import { FlywheelGoalCard } from "~/components/customise-flywheel-goal/FlywheelGoalCard";
import { FlywheelGoalPreview } from "~/components/customise-flywheel-goal/FlywheelGoalPreview";
import {
  Input,
  SelectInput
} from "~/components/form";
import { Icon } from "~/components/Icon";
import { NoWrap } from "~/components/NoWrap";
import { ReviewContentWrapper } from "~/components/review/ReviewContentWrapper";
import { Spacer } from "~/components/Spacer";
import { routes } from "~/constants/routes";
import {
  useCustomiseFlywheelGoalDispatch,
  useCustomiseFlywheelGoalState
} from "~/contexts/CustomiseFlywheelGoalContext/CustomiseFlywheelGoalContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useSideNavigation } from "~/contexts/SideNavigationContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useCreateFlywheelGoalAndSubgoals } from "~/hooks/flywheel-goal";
import { useError } from "~/hooks/useError";
import { useIsMobile } from "~/hooks/useIsMobile";
import { breakDownSubgoals } from "~/utils/breakDownSubgoals";
import { constructUtcDate } from "~/utils/dates/constructUtcDate";
import { getUnitSymbol } from "~/utils/getUnitSymbol";
import { cleanNumberInput } from "~/utils/validation/cleanNumberInput";

import type { CreateSubgoalType } from "@roda/core/flywheel-goal/createFlywheelGoalAndSubgoals";
import type {
  Currency,
  User
} from "@roda/graphql/genql";

export const ChangeFlywheelGoalSchema = z.object({
  name: z.string().trim().min(1, "Name is required").refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }),
  ownerEmail: z.string().trim().min(1, "Email is required").email().max(100, "Maximum 100 characters"),
  updateFrequency: z.string().trim().min(1, "Update frequency is required").max(100, "Maximum 100 characters"),
  goal: z.string().trim().min(1, "Goal is required").regex(/^[\d.]+$/, "Please choose a valid goal")
});

export const CustomiseFlywheelGoalScreen: React.FC = () => {
  const { flywheel } = useSelectedFlywheel();
  const { user } = useCurrentUser();
  const [ subgoals, setSubgoals ] = useState<CreateSubgoalType[] | null>([]);
  const customiseFlywheelGoalDispatch = useCustomiseFlywheelGoalDispatch();
  const { isCustomisingMeasurement, flywheelGoal } = useCustomiseFlywheelGoalState();
  const { assertGraphQLSuccess } = useError();
  const [ createFlywheelGoalRes, createFlywheelGoalReq ] = useCreateFlywheelGoalAndSubgoals();
  const [ showOwnerConfirmation, setShowOwnerConfirmation ] = useState(false);
  const [ showWarningModal, setWarningModal ] = useState(false);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const params = useParams() as { companyId?: string };
  const { setHideMainSideNav } = useSideNavigation();
  // Get an existing flywheel goal - if in createFlywheelMode always use the onboardingFlywheelGoal
  const existingFlywheelGoal = flywheel?.latestFlywheelGoal;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    clearErrors,
    formState: { errors }
  } = useForm<z.infer<typeof ChangeFlywheelGoalSchema>>({
    resolver: zodResolver(ChangeFlywheelGoalSchema),
    defaultValues: {
      name: existingFlywheelGoal?.name,
      ownerEmail: existingFlywheelGoal?.owner?.email,
      updateFrequency: UpdateFrequency.QUARTERLY,
      goal: existingFlywheelGoal?.goal
    },
    shouldFocusError: false
  });

  // If there's a goal already create subgoals in local state
  useEffect(() => {
    if (existingFlywheelGoal?.goal) {
      handleCreateSubgoals(existingFlywheelGoal?.goal);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ existingFlywheelGoal ]);

  // On render of this page - always hide the main side nav
  useEffect(() => {
    setHideMainSideNav(true);
  }, [ setHideMainSideNav ]);

  const watchedGoal = watch("goal");

  // Get cap percentage
  const exceedsPercentageMax = useMemo(() => flywheelGoal?.unitTypeLabel === FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE && (flywheelGoal.cap !== null && flywheelGoal.cap !== undefined) ? +watchedGoal > flywheelGoal.cap : false, [
    flywheelGoal?.cap,
    flywheelGoal?.unitTypeLabel,
    watchedGoal
  ]);

  /**
   * Function for getting the flywheel template name by id
   */
  const getFlywheelTemplateName = useCallback((id?: number) => {
    const name = availableFlywheelTemplates.find(i => +i.id === id)?.name || FlywheelGoalEnum.GROWTH;

    return name as FlywheelGoalEnum;
  }, [ ]);

  const availableFlywheelGoalUnits = useMemo(() => flywheel?.flywheelTemplateId ? flywheelGoalUnits[ getFlywheelTemplateName(flywheel.flywheelTemplateId) ] : [
    ...flywheelGoalUnits.Growth,
    ...flywheelGoalUnits.Impact,
    ...flywheelGoalUnits[ "Operations Management" ]
  ], [ flywheel?.flywheelTemplateId, getFlywheelTemplateName ]);

  const handleSelectUnit = useCallback((name: string) => {
    name && setValue("name", name);
    const unit = availableFlywheelGoalUnits.find(unit => unit.name === name);

    customiseFlywheelGoalDispatch({
      type: "SET_SELECTED_FLYWHEEL_GOAL",
      flywheelGoal: unit
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setValue ]);

  const unitIcon = useMemo(() => {
    return getUnitSymbol(flywheelGoal?.unitTypeLabel, flywheel?.currency as Currency);
  }, [ flywheel?.currency, flywheelGoal ]);

  const handleCreateSubgoals = useCallback((goal?: string) => {
    goal && setValue("goal", goal);
    const achieveByDate = existingFlywheelGoal?.achieveBy;
    const mainGoal = Number(goal) || watch("goal");
    const flywheelGoalTypeLabel = flywheelGoal?.unitTypeLabel || FlywheelTemplateUnitTypeLabelEnum.COUNT;

    if (achieveByDate && mainGoal && flywheelGoalTypeLabel) {
      setSubgoals(breakDownSubgoals({
        achieveByDate,
        mainGoal: mainGoal.toString(),
        timeframe: "QUARTERLY", // default to quarters for now
        typeLabel: flywheelGoalTypeLabel as FlywheelTemplateUnitTypeLabelEnum
      }));
    }
  }, [
    setValue,
    existingFlywheelGoal?.achieveBy,
    watch,
    flywheelGoal?.unitTypeLabel
  ]);

  // Submit handler
  const onSubmit = handleSubmit(fields => {
    const flywheelId = flywheel?.id;
    const flywheelGoalId = existingFlywheelGoal?.id;

    if (!!subgoals && flywheelId && existingFlywheelGoal?.achieveBy) {
      if (flywheelGoal && !showOwnerConfirmation) {
        // Create flywheel goal and subgoals for flywheel
        createFlywheelGoalReq({
          flywheelGoalId: flywheelGoalId ? +flywheelGoalId : undefined,
          flywheelId: +flywheelId,
          achieveBy: constructUtcDate(existingFlywheelGoal?.achieveBy),
          subgoals,
          goal: fields.goal.toString(),
          name: flywheelGoal.name!,
          unitName: flywheelGoal.unitName!,
          unitType: "number",
          unitTypeLabel: flywheelGoal.unitTypeLabel || FlywheelTemplateUnitTypeLabelEnum.COUNT,
          unitDisplay: flywheelGoal.unitDisplay || "quarterly",
          unitDescription: flywheelGoal.unitDescription!,
          cap: flywheelGoal.cap || null,
          ownerEmail: fields.ownerEmail,
          updateFrequency: "MONTHLY", // Default to monthly - they will be able to change this in the next step
          fromDate: subgoals[ 0 ].startDate// Start of the first subgoal
        }).then(res => {
          assertGraphQLSuccess(res);
          navigate(routes.dashboard(params?.companyId));
        });
      }
    }
  });

  return (
    <>
      <ReviewContentWrapper
        title="Edit flywheel goal"
        rightContent={(
          <div
            className={`flex items-center h-full flex-1 justify-center shrink ${isMobile ? "max-h-[40vh] max-w-[100dvw] overflow-x-visible pb-10" : "overflow-visible"}`}
          >
            <FlywheelGoalPreview
              target={watch("goal")}
              flywheelGoal={flywheelGoal}
            />
          </div>
        )}

        leftContent={(
          <div className="w-full flex flex-col flex-1 *:min-h-0">
            {isCustomisingMeasurement ? (
              <CustomiseFlywheelGoal />
            ) : (

              <form
                className="flex flex-col flex-1 *:min-h-0 gap-5"
              >

                <div className="flex flex-col gap-y-5 *:min-h-0">
                  {flywheelGoal ? (
                    <FlywheelGoalCard
                      onClick={() => customiseFlywheelGoalDispatch({ type: "CUSTOMISE_GOAL" })}
                    />
                  ) : (
                    <SelectInput
                      value=""
                      onValueChange={val => handleSelectUnit(val)}
                      label="How will you measure your goal?"
                      placeholder="*Pick a measure*"
                      options={availableFlywheelGoalUnits.map(unit => unit.name)}
                      optionsDescriptions={availableFlywheelGoalUnits.map(unit => unit.unitDescription)}
                      renderButton={(
                        <Button
                          title="Create new measurement"
                          iconComponent={<Icon.PlusCircle />}
                          className="bg-brand-cold-metal-100 m-2 min-h-10 text-brand-cold-metal-800"
                          onClick={() => {
                            customiseFlywheelGoalDispatch({ type: "CUSTOMISE_GOAL" });
                          }}
                        />
                      )}
                      hasError={!!errors.name}
                      errorMessage={errors.name?.message}
                      clearFormError={() => clearErrors("name")}
                    />
                  )}

                  {(flywheelGoal) && (
                    <>
                      <Input
                        {...register("ownerEmail")}
                        label="Who is accountable for this?"
                        name="ownerEmail"
                        inputMode="email"
                        hasError={!!errors.ownerEmail}
                        errorMessage={errors.ownerEmail?.message}
                        suggestions={(user?.company?.activeUsers as User[])?.map(user => user.email)}
                        onSuggestionSelect={val => setValue("ownerEmail", val, {
                          shouldDirty: true,
                          shouldTouch: true
                        })}
                      />

                      <Input
                        {...register("goal", {
                          onChange: e => {
                            const cleanedValue = cleanNumberInput(e.target.value);

                            setValue("goal", cleanedValue);
                            handleCreateSubgoals(cleanedValue);
                          }
                        })}
                        label={`Enter your goal for ${flywheelGoal?.name?.toLowerCase() || getValues("name")?.toLowerCase()}`}
                        name="goal"
                        min={1}
                        id="goal"
                        inputMode="numeric"
                        autoComplete="off"
                        autoCorrect="off"
                        placeholder="Enter your goal"
                        iconPosition={unitIcon === "%" ? "right" : "left"}
                        maxLength={14}
                        iconComponent={<p>{unitIcon}</p>}
                        hasError={!!errors.goal || exceedsPercentageMax}
                        errorMessage={exceedsPercentageMax ? `Max of ${flywheelGoal?.cap}% reached` : errors.goal?.message}
                      />

                    </>
                  )}

                </div>

                {isMobile && (<Spacer />)}

                <div className={`flex gap-x-3 self-start pb-5 ${isMobile ? "flex-col *:min-h-0 w-full gap-3" : "flex-row"} ${isMobile && watch("goal") ? "" : "mt-10"}`}>
                  <Button
                    title="Back"
                    onClick={() => navigate(routes.home)}
                    className="bg-brand-cold-metal-200 hover:contrast-75 text-brand-cold-metal-900"
                  />

                  <Button
                    title="Change goal"
                    disabled={exceedsPercentageMax}
                    className={`px-6 ${isMobile ? "order-first" : ""}`}
                    onClick={() => setWarningModal(true)}
                    loading={createFlywheelGoalRes.fetching}
                  />
                </div>

              </form>
            )}

          </div>
        )}
      />

      {/* Owner Email verification modal */}
      <ConfirmationPopup
        subtitle={(
          <div className="mb-4">
            <CalloutBadge variant="warning" />
          </div>
        )}
        isOpen={showWarningModal}
        title="Change goal type?"
        cancelText="Cancel"
        onCancel={() => {
          setWarningModal(false);
        }}
        loading={createFlywheelGoalRes.fetching}
        onContinue={() => {
          onSubmit();
        }}
        continueText="Yes, change goal"
        text={(
          <p className="text-pretty">
            Changing your goal type will remove all saved data relating to the current goal. This will not affect your metrics.
          </p>
        )}
      />

      {/* Owner Email verification modal */}
      <ConfirmationPopup
        isOpen={showOwnerConfirmation}
        onContinue={() => setShowOwnerConfirmation(false)}
        continueText="Okay"
        title="User will be invited"
        text={(
          <>
            This will invite
            {" "}

            <NoWrap
              wrap
              className="bg-gray-300/50 box-decoration-clone rounded px-0.5"
            >
              {getValues("ownerEmail")}
            </NoWrap>

            {" "}
            to your organisation as an admin. When they accept your invitation, they will be responsible for posting updates for this flywheel goal.
          </>
        )}
        iconComponent={(
          <Icon.MailOpen
            size={28}
            className="text-brand-cold-metal-700"
          />
        )}
      />
    </>

  );
};