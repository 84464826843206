import {
  useEffect,
  useState
} from "react";
import DatePicker from "react-datepicker";

import { CheckInBadge } from "~/components/check-in/CheckInBadge";
import { InputLabel } from "~/components/form";
import { Icon } from "~/components/Icon";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useSetCurrentDate } from "~/hooks/current-date/use-set-current-date";
import { useError } from "~/hooks/useError";
import dayjs from "~/utils/dayjs";
import { getFakeNowDate } from "~/utils/getCurrentFakeDateFromStorage";

export const CurrentDatePicker = () => {
  const { refetch: refetchCurrentUser } = useCurrentUser();

  const {
    flywheelStartWeek, flywheelEndDate, refetchFlywheel
  } = useSelectedFlywheel();

  const { assertGraphQLSuccess, handleRodaError } = useError();
  const [ _setCurrentDateRes, setCurrentDateReq ] = useSetCurrentDate();
  const [ currentDate, setCurrentDate ] = useState<Date | null>(null);
  // Allow to go back a year/or a year in the future
  const minDate = dayjs(flywheelStartWeek).subtract(1, "year");
  const maxDate = dayjs(flywheelEndDate).add(1, "year");

  // Get current fake date on load from local storage
  useEffect(() => {
    const currentFakeNow = getFakeNowDate();

    setCurrentDate(currentFakeNow);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handler for setting current date in state and in the backend
  const handleSetCurrentDate = (date: Date | null) => {
    setCurrentDateReq({ currentDate: date }) // Set current date in the db
      .then(res => {
        assertGraphQLSuccess(res);
        setCurrentDate(date); // Set in state
        refetchFlywheel();
        refetchCurrentUser(); // Refetch currentUser session to get updated fakeNowDate
      })
      .catch(e => {
        setCurrentDate(getFakeNowDate());
        handleRodaError(e, "Something went wrong. Please try again.");
      });
  };

  return (
    <div className="flex flex-col gap-y-1 p-3 bg-slate-100 opacity-85 rounded-lg">
      <InputLabel htmlFor="label">
        <div className="flex flex-row gap-x-4">
          <span>Current date</span>

          <CheckInBadge
            text="Dev mode"
            icon={<Icon.Attention className="w-2.5" />}
            bgColour="bg-brand-attention-orange-200"
          />
        </div>

      </InputLabel>

      <DatePicker
        required
        className="w-[150px] rounded-lg sm:text-base border items-center justify-center flex flex-row m-0 text-brand-cold-metal-500"
        selected={currentDate}
        onChange={date => handleSetCurrentDate(date)}
        dateFormat="dd/MM/yyyy"
        icon={<Icon.Calendar className="self-center mt-[3px]" />}
        minDate={minDate.toDate()}
        maxDate={maxDate.toDate()}
        showIcon
      />
    </div>

  );
};