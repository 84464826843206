import {
  useEffect,
  useRef,
  useState
} from "react";

import { Button } from "~/components/Button";
import { AccordionItem } from "~/components/onboarding/AccordionItem";
import { AccordionWrapper } from "~/components/onboarding/AccordionWrapper";
import {
  DisplayModes,
  IntroFlywheel
} from "~/components/onboarding/intro-flywheel/IntroFlywheel";
import type { OnboardingContentStepProps } from "~/components/onboarding/OnboardingContent";
import { OnboardingContentWrapper } from "~/components/onboarding/OnboardingContentWrapper";
import { Spacer } from "~/components/Spacer";
import { dummyIntroFlywheel } from "~/constants/misc";
import type { StepType } from "~/contexts/OnboardingContext/onboarding-reducer";
import {
  useOnboardingDispatch,
  useOnboardingState
} from "~/contexts/OnboardingContext/OnboardingContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useSetupFlywheel } from "~/hooks/flywheel";
import { useError } from "~/hooks/useError";
import { useIsMobile } from "~/hooks/useIsMobile";
import { formatGoalNumber } from "~/utils/formatGoalNumber";
import { getUnitSymbol } from "~/utils/getUnitSymbol";

import type { Currency } from "@roda/graphql/genql";

interface FlywheelIntroExtraProps {
  isGettingStartedPage?: boolean;
  nonOnboardingMode?: boolean;
  overrideTitle?: string;
  overrideSubtitle?: string;
}

export const FlywheelIntro: React.FC<OnboardingContentStepProps & FlywheelIntroExtraProps> = ({
  onNextStep, changeStep, onBack, isGettingStartedPage, nonOnboardingMode, overrideSubtitle, overrideTitle, createFlywheelMode
}) => {
  const { user: currentUser, refetch } = useCurrentUser();
  const [ { fetching }, setupFlywheelReq ] = useSetupFlywheel();
  const [ displayMode, setDisplayMode ] = useState(DisplayModes.OVERVIEW);
  const { company, flywheel: onboardingFlywheel } = useOnboardingState();
  const dispatch = useOnboardingDispatch();
  const { handleRodaError } = useError();
  const isMobile = useIsMobile();
  const flywheelContainerRef = useRef<HTMLDivElement>(null);
  const [ flywheelSize, setFlywheelSize ] = useState(0);
  // If on createFlywheelMode always use the onboardingFlywheel - we don't want to use the current flywheel that's in state
  const existingFlywheel = onboardingFlywheel;

  useEffect(() => {
    const updateFlywheelSize = () => {
      if (flywheelContainerRef.current) {
        const containerHeight = Math.min(flywheelContainerRef.current.offsetHeight, flywheelContainerRef.current.offsetWidth * 0.5); // slightly smaller than other screen since this one has annotations
        const size = isMobile ? containerHeight * 0.65 : containerHeight * 0.75;

        setFlywheelSize(size);
      }
    };

    // Call the update function initially and on window resize
    updateFlywheelSize();
    window.addEventListener("resize", updateFlywheelSize);

    return () => {
      window.removeEventListener("resize", updateFlywheelSize);
    };
  }, [ isMobile, flywheelContainerRef.current?.offsetHeight ]);

  const handleNextStep = () => {
    setupFlywheelReq({
      name: existingFlywheel?.name || "New Flywheel",
      flywheelTemplateId: existingFlywheel?.flywheelTemplateId,
      currency: company?.currency ? company?.currency as Currency : "GBP", // Default to GBP if for any reason there's not a currency in company
      updateFrequency: "WEEKLY", // Default for v1
      timeframe: "QUARTERLY", // Default for v1
      companyId: currentUser?.companyId,
      flywheelId: existingFlywheel?.id ? +existingFlywheel.id : undefined // pass existing flywheel id to update
    }).then(setupFlywheelRes => {
      if (setupFlywheelRes.data) {
        const flywheelData = setupFlywheelRes.data.setupFlywheel;

        refetch();

        // Save flywheel in onboarding state
        dispatch({
          type: "SET_FLYWHEEL",
          flywheel: {
            id: flywheelData?.id,
            name: flywheelData?.name,
            flywheelTemplateId: flywheelData?.flywheelTemplateId,
            companyId: flywheelData?.companyId || currentUser?.companyId || undefined,
            currency: flywheelData?.currency,
            updateFrequency: flywheelData?.updateFrequency,
            timeframe: flywheelData?.timeframe,
            ownerId: flywheelData?.ownerId,
            steps: onboardingFlywheel?.steps ?? flywheelData?.steps as StepType[] // keep existing steps
          }
        });
        onNextStep();
      }
    }).catch(error => {
      handleRodaError(error, "An error occured whilst trying to setup your flywheel. Please try again later.");
    });
  };

  return (
    <OnboardingContentWrapper
      createFlywheelMode={createFlywheelMode}
      hideLogo={nonOnboardingMode || createFlywheelMode}
      overrideTitle={overrideTitle}
      overrideSubtitle={overrideSubtitle}
      changeStep={changeStep}
      leftContent={(
        <div className="flex flex-1 flex-col w-full">
          <AccordionWrapper firstChildOpen>
            <AccordionItem
              title="Flywheel overview"
              text="The flywheel shows the series of repeating steps an organisation should take to build momentum in delivering its strategic objectives."
              onPress={() => setDisplayMode(DisplayModes.OVERVIEW)}
            />

            <AccordionItem
              title="Flywheel goal"
              text="The flywheel goal is the primary goal for your organisation, and the focus of the flywheel. Progress against this goal is updated monthly, and each quarter there will be a review and update of your flywheel goal."
              onPress={() => setDisplayMode(DisplayModes.GOAL)}
            />

            <AccordionItem
              title="Steps"
              text="Each step on the flywheel reflects an outcome an organisation must achieve to deliver its priority. Steps are connected, building on each other. A flywheel can have 3-6 steps."
              onPress={() => setDisplayMode(DisplayModes.STEPS)}
            />

            <AccordionItem
              title="Metrics"
              text="A metric is a measurable indicator of performance. Each step on the flywheel can have between one and six metrics that measure performance. Metrics are updated weekly through a check-in process."
              onPress={() => setDisplayMode(DisplayModes.METRICS)}
            />

            <AccordionItem
              title="Health indicators"
              text="The health indicators, at a step and metric level, let you see at a glance whether your organisation is performing at target levels, or not. A green indicator means you are on target, amber means you are below target levels and blue means there's a check-in due."
              onPress={() => setDisplayMode(DisplayModes.HEALTH_INDICATORS)}
            />
          </AccordionWrapper>

          {isMobile && (<Spacer />)}

          {isGettingStartedPage ? (
            <div className={`flex gap-x-3 self-start pb-5 mt-10 ${isMobile ? "flex-col w-full gap-3" : "flex-row"}`}>
              <Button
                title="Done"
                className={`px-6 ${isMobile ? "order-first" : ""}`}
                onClick={onBack}
              />
            </div>
          ) : (
            <div className={`flex gap-x-3 self-start pb-5 mt-10 ${isMobile ? "flex-col w-full gap-3" : "flex-row"}`}>
              {onBack && (
                <Button
                  title="Back"
                  onClick={onBack}
                  className="bg-brand-cold-metal-100 hover:contrast-75 w-full text-brand-cold-metal-900"
                />
              )}

              <Button
                title="Next"
                className={`px-6 ${isMobile ? "order-first" : ""}`}
                onClick={handleNextStep}
                loading={fetching}
              />
            </div>
          )}

        </div>
      )}
      rightContent={(
        <div
          ref={flywheelContainerRef}
          className={`relative flex-1 grid place-items-center ${isMobile ? "h-full max-h-[1000px] w-full max-w-[1000px] overflow-x-visible pb-10" : "overflow-visible"} ${nonOnboardingMode && isMobile ? "mt-5" : ""}`}
        >
          <IntroFlywheel
            displayMode={displayMode}
            size={flywheelSize}
            flywheel={dummyIntroFlywheel}
            innerContent={(
              <p
                style={{ opacity: (displayMode !== DisplayModes.OVERVIEW) ? 1 : 0 }}
                className="select-none font-bold text-brand-text text-2xl lg:text-4xl xl:text-5xl transition-opacity duration-500 ease-in-out"
              >{formatGoalNumber(2800000, getUnitSymbol("Currency", company?.currency as Currency || "GBP"), {
                stripTrailingZeros: true,
                shouldCompact: true
              })}
              </p>
            )}
          />
        </div>

      )}
    />

  );
};