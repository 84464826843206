import {
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useToggle } from "usehooks-ts";

import { Button } from "~/components/Button";
import { CalloutBadge } from "~/components/CalloutBadge";
import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import { CustomiseFlywheelTemplate } from "~/components/customise-flywheel-goal/CustomiseFlywheelTemplate";
import { Divider } from "~/components/Divider";
import { Icon } from "~/components/Icon";
import { NoWrap } from "~/components/NoWrap";
import { Spacer } from "~/components/Spacer";
import { routes } from "~/constants/routes";
import {
  useCustomiseFlywheelGoalDispatch,
  useCustomiseFlywheelGoalState
} from "~/contexts/CustomiseFlywheelGoalContext/CustomiseFlywheelGoalContext";
import { useFlywheelLoader } from "~/contexts/FlywheelLoaderContext";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useSideNavigation } from "~/contexts/SideNavigationContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useCustomiseFlywheel } from "~/hooks/flywheel/use-customise-flywheel";
import { useDeleteFlywheel } from "~/hooks/flywheel/use-delete-flywheel";
import { useError } from "~/hooks/useError";
import { useIsMobile } from "~/hooks/useIsMobile";

export const CustomiseFlywheel = () => {
  const { flywheel } = useSelectedFlywheel();
  const { refetch: refetchSessionWithFlywheels } = useCurrentUser();

  const {
    availableFlywheels, setActiveFlywheelId, onDeleteFlywheel
  } = useFlywheelLoader();

  const [ deletionState, deleteFlywheelMutation ] = useDeleteFlywheel();
  const [ customiseFlywheelRes, customiseFlywheelReq ] = useCustomiseFlywheel();
  const dispatch = useCustomiseFlywheelGoalDispatch();
  const { flywheelTemplate } = useCustomiseFlywheelGoalState();
  const isMobile = useIsMobile();
  // State for managing update status
  const { assertGraphQLSuccess, handleRodaError } = useError();
  const { currentCompany } = useRodaAdminCompaniesContext();
  const navigate = useNavigate();
  const { setHideMainSideNav } = useSideNavigation();
  const [ deleteConfirmation, toggleDeleteConfirmation ] = useToggle(false);
  const [ formWarning, setFormWarning ] = useState(false);

  // On render of this page - always show the main side nav
  useEffect(() => {
    setHideMainSideNav(false);
  }, [ setHideMainSideNav ]);

  const initialAliases = useMemo(() => flywheel?.steps?.map(step => {
    return {
      stepId: step.id,
      alias: step.alias || ""
    };
  }) || [], [ flywheel?.steps ]);

  const resetFlywheelTemplateState = useCallback(() => {
    dispatch({
      type: "SET_SELECTED_FLYWHEEL_TEMPLATE",
      flywheelTemplate: {
        name: flywheel?.name,
        id: flywheel?.flywheelTemplateId,
        steps: flywheel?.steps?.map((step, idx) => ({
          id: step.id,
          name: step.name,
          order: step.order || idx,
          description: "",
          metrics: []
        })) || []
      }
    });
  }, [ flywheel, dispatch ]);

  // Always update state on render
  useEffect(() => {
    resetFlywheelTemplateState();
  }, [ initialAliases, resetFlywheelTemplateState ]);

  // Function for handling form submission
  const onSubmit = useCallback(() => {
    if (!flywheel || !flywheelTemplate?.name || !flywheel.steps?.length) return;

    customiseFlywheelReq({
      flywheelId: +flywheel.id,
      name: flywheelTemplate?.name,
      steps: flywheelTemplate?.steps.map((step, idx) => {
        return {
          stepId: +step.id,
          name: step.name,
          order: idx
        };
      })
    })
      .then(assertGraphQLSuccess)
      .then(() => {
        toast.success("Flywheel updated");
        setFormWarning(false);
      })
      .catch(e => handleRodaError(e, "Error customising flywheel"));
  }, [
    assertGraphQLSuccess,
    customiseFlywheelReq,
    flywheel,
    flywheelTemplate?.name,
    flywheelTemplate?.steps,
    handleRodaError
  ]);

  const deleteFlywheel = async () => {
    const res = await deleteFlywheelMutation({ flywheelId: +flywheel!.id });

    assertGraphQLSuccess(res);
    refetchSessionWithFlywheels();
    onDeleteFlywheel(+flywheel!.id);

    if (res.data?.softDeleteFlywheel.deletedAt) {
      const remainingFlywheel = availableFlywheels?.find(fw => fw.id !== res.data?.softDeleteFlywheel.id);

      if (remainingFlywheel) {
        setActiveFlywheelId(remainingFlywheel?.id);
      }

      navigate(routes.dashboard(currentCompany ? currentCompany.id : undefined));
    }
  };

  const canDelete = !!(currentCompany?.flywheels ? currentCompany.flywheels?.length > 1 : availableFlywheels && availableFlywheels.length > 1);

  // Is the form dirty,
  // Or, compare the state of flywheelTemplate and flywheel in state
  const isPageDirty = (JSON.stringify({
    steps: flywheelTemplate?.steps.map(s => s.name + s.order),
    name: flywheelTemplate?.name
  }) !== JSON.stringify({
    steps: flywheel?.steps?.map(s => s.name + s.order),
    name: flywheel?.name
  }));

  return (
    <div className="flex flex-col w-full flex-1 bg-white lg:px-10 px-5 pb-20">
      {!isMobile && (
        <header
          className=" flex flex-col w-full absolute p-4"
          id="goal-back-header"
        >
          <div>
            <Button
              title="Back"
              iconComponent={<Icon.ChevronLeft />}
              className="bg-brand-cold-metal-200 text-brand-cold-metal-800 font-normal px-1 py-2 text-xs"
              onClick={() => navigate(routes.dashboard(currentCompany ? currentCompany.id : undefined))}
            />
          </div>

        </header>
      )}

      <div className="flex flex-col items-center justify-start w-full pt-5 max-w-screen-md xl:px-0 mx-auto">

        {/* Container for form */}
        <div className={`flex-1 w-full text-xs sm:text-sm flex flex-col ${isMobile ? "mt-4 gap-4" : "mt-10 gap-8"}`}>

          {/* Header */}
          <div className="flex flex-col w-full">
            <div className={`font-bold text-left pb-2 ${isMobile ? "text-lg" : "text-2xl"}`}>Customise your Flywheel</div>

            <p className={`text-brand-cold-metal-500 ${isMobile ? "text-md" : "text-lg"}`}>Personalise your flywheel's name and steps to align with your business.</p>
          </div>

          <Divider />

          <form
            onSubmit={onSubmit}
            className="flex flex-col space-y-7"
          >

            <CustomiseFlywheelTemplate showButtons={false} />

            <br />

            {/* Action buttons for saving or discarding changes */}
            <div className={`flex gap-x-3 self-start ${isMobile ? "flex-col w-full gap-3 pb-5" : "flex-row"}`}>
              <Button
                type="button"
                disabled={!isPageDirty || flywheelTemplate?.steps.some(step => !step.name.length) || customiseFlywheelRes.fetching}
                loading={customiseFlywheelRes.fetching}
                title="Save"
                className="px-6 order-first lg:order-[unset]"
                onClick={() => {
                  if (flywheel?.steps?.some(existingStep => !flywheelTemplate?.steps.find(newStep => newStep.id === existingStep.id))) {
                    setFormWarning(true);
                  } else {
                    onSubmit();
                  }
                }}
              />

              {isPageDirty && (
                <Button
                  type="button"
                  disabled={customiseFlywheelRes.fetching}
                  onClick={() => {
                    resetFlywheelTemplateState();
                  }}
                  title="Discard changes"
                  className="bg-brand-cold-metal-100 text-brand-cold-metal-900 hover:contrast-75"
                />
              )}

            </div>

            <ConfirmationPopup
              subtitle={(
                <div className="mb-4">
                  <CalloutBadge variant="warning" />
                </div>
              )}
              isOpen={formWarning}
              title="You are deleting a step."
              cancelText="Cancel"
              onCancel={() => setFormWarning(false)}
              onContinue={onSubmit}
              continueText="Yes, delete"
              continueDisabled={customiseFlywheelRes.fetching}
              continueButtonClassName="bg-brand-error-red-500 hover:text-white"
              loading={customiseFlywheelRes.fetching}
              text={(
                <p className="text-pretty">
                  Deleting a step means that all metrics and check-in history for that step will be deleted too.
                </p>
              )}
            />
          </form>
        </div>

        <Spacer className="min-h-6" />

        {canDelete && (
          <>
            <Divider />

            <Spacer className="min-h-6" />

            <footer className="py-4 w-full">
              {/* only able to delete when we have more than one flywheel */}
              <Button
                onClick={toggleDeleteConfirmation}
                iconComponent={<Icon.Bin className="mr-2" />}
                title={`Delete "${flywheel?.name}"`}
                className="bg-brand-error-red-500 text-brand-error-red-100 px-4 w-full hover:text-white desktop:w-auto desktop:min-w-[30%]"
              />

              <ConfirmationPopup
                subtitle={(
                  <div className="mb-4">
                    <CalloutBadge variant="warning" />
                  </div>
                )}
                isOpen={deleteConfirmation}
                title="Delete flywheel?"
                cancelText="Cancel"
                onCancel={toggleDeleteConfirmation}
                onContinue={deleteFlywheel}
                continueText="Yes, delete"
                continueDisabled={deletionState.fetching}
                continueButtonClassName="bg-brand-error-red-500 hover:text-white"
                loading={deletionState.fetching}
                text={(
                  <p className="text-pretty">
                    Are you sure you want to delete the flywheel <NoWrap>"{flywheel?.name}"?</NoWrap> This action is irreversible.
                  </p>
                )}
              />

            </footer>
          </>
        )}
      </div>

    </div>
  );
};
