import { zodResolver } from "@hookform/resolvers/zod";
import {
  enumUserRole,
  type User
} from "@roda/graphql/genql";
import { ReviewPeriod } from "@roda/shared/types";
import {
  useEffect,
  useMemo,
  useState
} from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  useNavigate,
  useParams
} from "react-router-dom";

import { Avatar } from "~/components/Avatar";
import { Button } from "~/components/Button";
import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import {
  Input,
  SelectInput
} from "~/components/form";
import { ReviewMetricSchema } from "~/components/form/formSchemas";
import { Icon } from "~/components/Icon";
import { MetricProgressChart } from "~/components/metric/MetricProgressChart";
import { Spacer } from "~/components/Spacer";
import { routes } from "~/constants/routes";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useSideNavigation } from "~/contexts/SideNavigationContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useReviewMetric } from "~/hooks/metric/use-review-metric";
import { useError } from "~/hooks/useError";
import { useIsMobile } from "~/hooks/useIsMobile";
import dayjs from "~/utils/dayjs";
import { formatGoalNumber } from "~/utils/formatGoalNumber";
import { getUnitSymbol } from "~/utils/getUnitSymbol";
import { getWeekMetricTarget } from "~/utils/getWeekMetricTarget";
import { cleanNumberInput } from "~/utils/validation/cleanNumberInput";
import { trimTrailingDots } from "~/utils/validation/trimTrailingDots";

import type { MetricType } from "@roda/core/metric";
import type { z } from "zod";

export const ReviewMetric = () => {
  const params = useParams() as { metricId: MetricType["id"] };
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { setHideMainSideNav } = useSideNavigation();
  const { user } = useCurrentUser();
  const { handleRodaError } = useError();
  // Permissions checks
  const isAdmin = user?.role === enumUserRole.ADMIN;
  const isRodaAdmin = user?.role === enumUserRole.RODA_ADMIN;

  // Flywheel data
  const {
    flywheel, flywheelSubgoals, checkInSubgoal, subgoalInReview, activeFlywheelReview
  } = useSelectedFlywheel();

  const isMetricOwner = !!activeFlywheelReview?.metrics?.find(metric => metric.owner?.id === user?.id);
  const { currentCompany } = useRodaAdminCompaniesContext();
  const activeCompanyUsers = useMemo(() => currentCompany ? currentCompany.activeUsers : user?.company?.activeUsers, [ currentCompany, user?.company?.activeUsers ]);
  const checkInSubgoalIdx = useMemo(() => flywheelSubgoals?.findIndex(s => s.id === checkInSubgoal?.id), [ checkInSubgoal, flywheelSubgoals ]);

  // Get the review subgoal idx
  // If it's a next year review this will ALWAYS be the first one
  const reviewSubgoalIdx = useMemo(() => {
    const index = activeFlywheelReview?.reviewPeriod === ReviewPeriod.NEXT_YEAR ? 0 : flywheelSubgoals && subgoalInReview ? flywheelSubgoals.findIndex(subgoal => subgoal.id === subgoalInReview.id) : undefined;

    return index !== -1 ? index : undefined;
  }, [
    activeFlywheelReview?.reviewPeriod,
    flywheelSubgoals,
    subgoalInReview
  ]);

  const currentQuarterMetrics = useMemo(() => flywheel?.steps ? flywheel?.steps?.flatMap(step => step.metrics) : [], [ flywheel?.steps ]);
  const reviewQuarterMetrics = useMemo(() => activeFlywheelReview?.metrics, [ activeFlywheelReview?.metrics ]);
  const isCheckInSubgoalInReview = useMemo(() => checkInSubgoalIdx === reviewSubgoalIdx, [ checkInSubgoalIdx, reviewSubgoalIdx ]);

  // Find the metric in the review quarter (i.e. it still exists for the quarter) or in the current quarter
  // metrics (i.e. it's been disabled for the review quarter)
  const metric = useMemo(() => reviewQuarterMetrics?.find(metric => metric?.id == params.metricId) || currentQuarterMetrics.find(metric => metric?.id == params.metricId), [
    reviewQuarterMetrics,
    currentQuarterMetrics,
    params.metricId
  ]);

  // Get a list of all metrics for the current metric's step - so we can tell if this is the only one
  const currentStepMetrics = useMemo(() => reviewQuarterMetrics?.filter(reviewMetric => reviewMetric.stepId === metric?.stepId), [ metric?.stepId, reviewQuarterMetrics ]);
  const [ disableConfirmationVisible, setDisableConfirmationVisible ] = useState(false);
  // Find the target that covers the quarter currently being reviewed
  const reviewTarget = useMemo(() => subgoalInReview && metric ? getWeekMetricTarget(metric.targets, dayjs(subgoalInReview.startDate)) : undefined, [ metric, subgoalInReview ]);
  const unitIcon = useMemo(() => getUnitSymbol(metric?.unitTypeLabel, flywheel?.currency), [ flywheel?.currency, metric?.unitTypeLabel ]);
  // Figure out if metric exists in the current quarter
  const metricExistsInCurrentQuarter = useMemo(() => !!currentQuarterMetrics?.find(metric => metric?.id === params.metricId), [ currentQuarterMetrics, params.metricId ]);
  // Metric is disabled if its toDate is before the start of the quarter we're reviewing
  const isDisabled = useMemo(() => metric?.toDate && dayjs(metric?.toDate).isBefore(subgoalInReview?.startDate), [ metric?.toDate, subgoalInReview?.startDate ]);
  // Metric is new for current quarter if its fromDate is the same as the subgoal startDate
  const isNewForReviewQuarter = useMemo(() => metric?.fromDate && dayjs(metric.fromDate).isSame(subgoalInReview?.startDate, "day"), [ metric?.fromDate, subgoalInReview?.startDate ]);
  const adminHasReviewed = useMemo(() => metric?.reviewers?.length ? !!metric.reviewers.find(reviewer => reviewer.user.role === enumUserRole.ADMIN || reviewer.user.role === enumUserRole.RODA_ADMIN) : false, [ metric?.reviewers ]);
  // Mutations
  const [ reviewMetricRes, reviewMetricReq ] = useReviewMetric();
  // Permissions to review
  const hasAccessToReview = (isAdmin || isRodaAdmin || isMetricOwner) && subgoalInReview;

  // On render of this page - always hide the main side nav
  useEffect(() => {
    setHideMainSideNav(true);
  }, [ setHideMainSideNav ]);

  const renderOptionLabel = (userId: string) => {
    const user = activeCompanyUsers?.find(user => user.id === userId);

    if (!user) return "User";

    return (
      <Avatar
        displayName
        px={30}
        user={user as User}
      />
    );
  };

  const ReviewMetricSchemaWithCap = ReviewMetricSchema(metric?.cap);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm<z.infer<typeof ReviewMetricSchemaWithCap>>({
    resolver: zodResolver(ReviewMetricSchemaWithCap),
    // Default to the current metric owner & target
    defaultValues: {
      ownerId: metric?.owner ? Number(metric?.owner?.id) : undefined,
      // Default this to the most recent target update, or undefined
      goal: reviewTarget ? reviewTarget.target : undefined
    },
    shouldFocusError: false
  });

  useEffect(() => {
    if (reviewTarget) {
      setValue("goal", reviewTarget.target);
    }

    if (metric?.owner) {
      setValue("ownerId", Number(metric.owner.id));
    }
  }, [
    metric?.owner,
    reviewTarget,
    setValue
  ]);

  const watchedOwnerId = watch("ownerId");
  const watchedGoal = watch("goal");
  const nextMetricTarget = metric?.targets?.find(target => target.isNext);

  const onSubmit = handleSubmit(values => {
    if (flywheel && subgoalInReview) {
      // TODO: Wire in update values here!
      reviewMetricReq({
        flywheelId: Number(flywheel.id),
        // TODO: Make sure this is the subgoal of the quarter being reviewed
        subgoalId: Number(subgoalInReview.id),
        metricId: Number(params.metricId),
        // Roda admins will pass this to target a specific company
        companyId: currentCompany ? Number(currentCompany.id) : undefined,
        target: values.goal ? trimTrailingDots(values.goal!) : undefined,
        ownerId: values.ownerId
      })
        .then(() => {
          // Then refetch the flywheel and return to the metrics list
          navigate(routes.reviewMetrics(currentCompany ? currentCompany.id : undefined));
          toast.success("Metric updated!");
        })
        .catch(e => {
          handleRodaError(e, "An error occurred! Try again later!");
        });
    }
  });

  // Disable the metric for the next quarter
  const handleToggleDisable = () => {
    if (flywheel && subgoalInReview) {
      // TODO: Wire in update values here!
      reviewMetricReq({
        flywheelId: Number(flywheel.id),
        // TODO: Make sure this is the subgoal of the quarter being reviewed
        subgoalId: Number(subgoalInReview.id),
        metricId: Number(params.metricId),
        // Roda admins will pass this to target a specific company
        companyId: currentCompany ? Number(currentCompany.id) : undefined,
        // Toggle this from its current state
        disabled: !isDisabled
      })
        .then(() => {
          // Then refetch the flywheel and return to the metrics list
          navigate(routes.reviewMetrics(currentCompany ? currentCompany.id : undefined));
          toast.success(`Metric ${!isDisabled ? "disabled!" : "re-activated!"}`);
        });
    }
  };

  useEffect(() => {
    if (!hasAccessToReview) navigate(routes.dashboard(currentCompany ? currentCompany.id : undefined));
  }, [
    currentCompany,
    hasAccessToReview,
    navigate
  ]);

  if (!metric) {
    navigate(routes.reviewMetrics(currentCompany ? currentCompany.id : undefined));

    return null;
  }

  return (
    <div className={`relative flex flex-1 bg-brand-cold-metal-50 ${isMobile ? "flex-col" : "flex-row"} `}>
      <div className={`flex flex-col z-10 bg-white flex-1 p-3 ${isMobile ? "order-2 w-full rounded-2xl" : "w-1/2"}`}>

        <div className={`flex flex-col flex-1 px-4 md:px-16 gap-2 w-[min(100%,700px)] mx-auto ${isMobile ? "mt-8" : "mt-[30%]"} mb-4`}>
          {metricExistsInCurrentQuarter && (
            <div>
              <Button
                title="Visit metric"
                iconComponent={<Icon.BarChart />}
                className="bg-brand-cold-metal-100 text-brand-cold-metal-900 p-2 sm:px-4 text-xs"
                onClick={() => navigate(routes.stepMetric(metric.stepId.toString(), metric.id, currentCompany ? currentCompany.id : undefined))}
              />
            </div>
          )}

          <p className="text-2xl font-bold text-brand-cold-metal-900">{metric.name}</p>

          <p className="text-brand-cold-metal-700">Who is responsible for updating this metric?</p>

          <form
            onSubmit={onSubmit}
            className="flex-full"
          >
            <div className="flex-full gap-2">
              <SelectInput
                placeholder="Choose a metric owner"
                value={String(watchedOwnerId)}
                onValueChange={val => setValue("ownerId", Number(val))}
                options={activeCompanyUsers ? activeCompanyUsers?.map(user => String(user.id)) : []}
                renderOptionLabel={renderOptionLabel}
                errorMessage={errors.ownerId?.message}
              // Only admins and Roda admins can update the owner
                disabled={(!isAdmin && !isRodaAdmin) || isDisabled}
              />

              <div className="flex flex-col md:flex-row gap-2">
                <div className="lg:max-w-[50%] w-full">
                  <SelectInput
                    placeholder="lg:Choose a metric type"
                    value={metric.name}
                  // Do nothing when this changes - it's not editable
                    onValueChange={() => null}
                    options={[ metric.name ]}
                  // Always disabled
                    disabled
                    label="Metric type"
                    className="flex-1"
                  />
                </div>

                <div className="lg:max-w-[50%] w-full">
                  <SelectInput
                    placeholder="Choose a measuring unit"
                    value={metric.unitName}
                  // Do nothing when this changes - it's not editable
                    onValueChange={() => null}
                    options={[ metric.unitName ]}
                  // Always disabled
                    disabled
                    label="Measuring unit"
                    className="flex-1"
                  />
                </div>
              </div>

              <Input
                label={`Enter the target for Q${reviewSubgoalIdx !== undefined ? reviewSubgoalIdx + 1 : 1} ${dayjs(subgoalInReview?.startDate).format("YYYY")}`}
                {...register("goal", {
                  onChange: e => {
                    const cleanedValue = cleanNumberInput(e.target.value);

                    setValue("goal", cleanedValue);
                  }
                })}
                hasError={!!errors.goal}
                errorMessage={errors.goal?.message}
                min={1}
                inputMode="numeric"
                autoComplete="off"
                autoCorrect="off"
                iconComponent={<p>{unitIcon}</p>}
                iconPosition={unitIcon === "%" ? "right" : "left"}
                disabled={isDisabled || (adminHasReviewed && !isAdmin && !isRodaAdmin)}
              />

              {isMobile && (<Spacer />)}

              <div className={`flex gap-x-3 self-start ${isMobile ? "flex-col w-full gap-3 pb-5" : "flex-row"}`}>
                <Button
                  title="Back"
                  className="bg-brand-cold-metal-100 text-brand-cold-metal-900"
                  onClick={() => navigate(routes.reviewMetrics(currentCompany ? currentCompany.id : undefined))}
                  disabled={reviewMetricRes.fetching}
                />

                <Button
                  title="Save"
                  type="submit"
                  onClick={onSubmit}
                  disabled={reviewMetricRes.fetching || isDisabled || (adminHasReviewed && !isAdmin && !isRodaAdmin)}
                  loading={reviewMetricRes.fetching}
                />

                {(isAdmin || isRodaAdmin) && (
                  <Button
                    title={isDisabled ? "Re-activate" : "Deactivate"}
                    iconComponent={isDisabled ? <Icon.PlusSquare /> : <Icon.XSquare />}
                    className="bg-transparent text-brand-cold-metal-600 sm:px-2"
                    onClick={() => setDisableConfirmationVisible(true)}
                  />
                )}

              </div>
            </div>
          </form>
        </div>
      </div>

      <div className={`flex flex-row lg:flex-col lg:w-1/2 bg-brand-cold-metal-50 lg:h-full sticky top-0 order-1 h-[300px] lg:items-center  p-6 lg:p-10 ${!isNewForReviewQuarter && !isCheckInSubgoalInReview ? "justify-start" : "justify-center"} md:justify-center gap-4 overflow-x-auto md:overflow-hidden`}>
        {!isNewForReviewQuarter && !isCheckInSubgoalInReview && (
          <div className={`border-[1.5px] border-solid flex flex-col rounded-xl min-w-[250px] w-[250px] lg:w-[300px] flex-wrap text-left bg-white p-4 gap-4 ${metric.unitDisplay === "weekly" ? "aspect-square" : "aspect-auto"}`}>
            <div className="flex flex-col *:min-w-0 *:min-h-0 h-full lg:gap-4">
              <div className="flex flex-row justify-between items-start">
                <div>
                  <p className="text-xl font-semibold">Quarter {checkInSubgoalIdx ? checkInSubgoalIdx + 1 : 1}</p>

                  <p className="lg:text-sm text-xs text-brand-cold-metal-400">{`${isMobile ? dayjs(checkInSubgoal?.startDate).format("MM/YYYY") + " - " + dayjs(checkInSubgoal?.endDate).format("MM/YYYY") : dayjs(checkInSubgoal?.startDate).format("MMMM YYYY") + " - " + dayjs(checkInSubgoal?.endDate).format("MMMM YYYY")}`}</p>
                </div>

                <div className="bg-brand-cold-metal-100 py-1 px-2 rounded-md shrink-0">
                  <p className="text-[10px] text-brand-cold-metal-800 font-semibold">Current</p>
                </div>

              </div>

              <div className="w-full flex flex-center max-h-[120px] lg:max-h-none mt-4 lg:mt-0">
                <MetricProgressChart
                  metric={metric}
                  selectedWeekStart={dayjs(checkInSubgoal?.endDate)}
                  hideTarget
                />
              </div>

              {isMobile && (<Spacer />)}

              {nextMetricTarget && (
                <div className="mt-4">
                  <p className="text-sm max-h-5 font-semibold text-brand-cold-metal-600 text-right truncate">
                    {`Target ${formatGoalNumber(Number(nextMetricTarget.target), metric.unitType, {
                      stripTrailingZeros: true,
                      shouldCompact: true
                    })}`}
                  </p>
                </div>
              )}
            </div>
          </div>
        )}

        {!isDisabled && (
          <div className={`border-[1.5px] border-solid flex flex-col rounded-xl min-w-[250px] w-[250px] lg:w-[300px] flex-wrap text-left bg-white p-4 ${metric.unitDisplay === "weekly" ? "aspect-square" : "aspect-auto"}`}>
            <div className="flex flex-col *:min-w-0 *:min-h-0 h-full lg:gap-4">
              <div className="flex flex-row justify-between items-start">
                <div>
                  <p className="text-xl font-semibold">Quarter {reviewSubgoalIdx ? reviewSubgoalIdx + 1 : 1}</p>

                  <p className="lg:text-sm text-xs text-brand-cold-metal-400">{`${isMobile ? dayjs(checkInSubgoal?.startDate).format("MM/YYYY") + " - " + dayjs(checkInSubgoal?.endDate).format("MM/YYYY") : dayjs(checkInSubgoal?.startDate).format("MMMM YYYY") + " - " + dayjs(checkInSubgoal?.endDate).format("MMMM YYYY")}`}</p>
                </div>

                <div className="bg-brand-cold-metal-100 py-1 px-2 rounded-md shrink-0">
                  <p className="text-[10px] text-brand-cold-metal-800 font-semibold">{isCheckInSubgoalInReview ? "Review-due" : "Coming up"}</p>
                </div>
              </div>

              <div className="w-full flex flex-center max-h-[120px] lg:max-h-none mt-4 lg:mt-0">
                <MetricProgressChart
                  metric={metric}
                  // Set the selected week as 6 weeks into the next quarter - because that will been the graphs show
                  // the first 6 week numbers on this graph!
                  selectedWeekStart={dayjs(subgoalInReview?.startDate).add(5, "weeks")}
                  hideTarget
                />
              </div>

              {isMobile && (<Spacer />)}

              {watchedGoal && (
                <div className="mt-4">
                  <p
                    className="text-sm max-h-5 font-semibold text-brand-cold-metal-600 text-right truncate max-w-[220px] lg:max-w-none"
                  >
                    {`Target ${formatGoalNumber(Number(watchedGoal), metric.unitType, {
                      stripTrailingZeros: true,
                      shouldCompact: true
                    })}`}
                  </p>
                </div>

              )}
            </div>
          </div>
        )}
      </div>

      <ConfirmationPopup
        isOpen={disableConfirmationVisible}
        title={`${isDisabled ? "Re-activate" : "Deactivate"} this metric`}
        onContinue={handleToggleDisable}
        // Disabled if this is the only metric for the step
        continueDisabled={(!isDisabled && (!currentStepMetrics || currentStepMetrics.length < 2))}
        continueText={isDisabled ? "Re-activate" : "Deactivate"}
        cancelText={((!isDisabled && (!currentStepMetrics || currentStepMetrics.length < 2))) ? "Ok" : "Cancel"}
        onCancel={() => setDisableConfirmationVisible(false)}
        loading={reviewMetricRes.fetching}
      >
        {(!isDisabled && (!currentStepMetrics || currentStepMetrics.length < 2)) ? (
          <div className="flex flex-col gap-2 text-sm">
            <p>You can't disable the only metric in a step.</p>

            <p>Create a new metric to replace this one before disabling it.</p>
          </div>
        ) : (
          <div className="flex flex-col gap-2 text-sm">
            <p>This will {isDisabled ? "re-enable" : "disable"} the metric from the start of next quarter.</p>

            <p>You'll be able to {isDisabled ? "disable" : "re-enable"} it again until the end of the review period.</p>
          </div>
        )}
      </ConfirmationPopup>
    </div>
  );
};