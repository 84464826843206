export const LoadingMetrics = () => {
  const DOT_SIZE = 6;
  const DOT_SPACING = 4;

  return (
    <div className="flex">
      {[
        1,
        2,
        3
      ].map(i => (
        <svg
          key={i}
          className={`dot-${i} mx-[${DOT_SPACING}px] text-brand-healthy-green-500  `} // Add class for each dot and use Tailwind for spacing
          width={DOT_SIZE + DOT_SPACING}
          height={DOT_SIZE + 2}

        >
          <circle
            cx={(DOT_SIZE + DOT_SPACING) / 2}
            cy={DOT_SIZE / 2}
            r={DOT_SIZE / 2}
            fill="current"
          />
        </svg>
      ))}
    </div>
  );
};