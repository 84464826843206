import clsx from "clsx";
import {
  useEffect,
  useState
} from "react";
import { FiChevronRight } from "react-icons/fi";

import {
  BaseFlywheel,
  BaseFlywheelStep
} from "~/components/flywheel/base";
import { LoadingMetrics } from "~/components/flywheel/roda/LoadingMetrics";
import { MetricStatusDots } from "~/components/flywheel/roda/MetricStatusDots";
import { StatusBubble } from "~/components/flywheel/roda/StatusBubble";
import type {
  FlywheelFromContextRequireLooseSteps,
  LooseStep
} from "~/components/flywheel/roda/types";
import { HealthStatus } from "~/components/flywheel/roda/types";
import { Icon } from "~/components/Icon";
import { Loading } from "~/components/Spinner";
import type { StepType } from "~/contexts/OnboardingContext/onboarding-reducer";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useIsMobile } from "~/hooks/useIsMobile";
import { getHealthStatusColour } from "~/utils/getHealthStatusColour";
import { getStepStatus } from "~/utils/getStepStatus";

import type { Step } from "@roda/graphql/genql";

type RodaFlywheelProps = {
  flywheel: NonNullable<FlywheelFromContextRequireLooseSteps>;
  size: NonNullable<React.ComponentProps<typeof BaseFlywheel>["size"]>;
  loading?: boolean;
  position: "centre" | "side" | "huge"
  shouldHoverSteps?: boolean;
  onStepClick?: (step: LooseStep<Step | StepType>) => void;
  /** override props for individual/all steps. *provided as an array, whereby
   * individual steps are targeted by their index* */
  stepsProps?: Array<Omit<React.ComponentProps<typeof BaseFlywheelStep>, "size">>;
  /**
   * render content inside the main circle
   *
   * *DOESN'T NEED TO BE A FN*-
   * since we are only passing through `size` which isn't really something we
   * would functionally use outside of styling, its also being provided as a CSS
   * var (`var(--size)`)
   **/
  renderInnerContent?: React.ComponentProps<typeof BaseFlywheel>["renderInnerContent"];
  /** optionally match the color of the background the flywheel sits on, to
   * emulate gaps */
  stepRingOutlineColor?: string;
  /** render the arrows between step circles */
  separators?: boolean;
  stepSize?: (step: LooseStep<Step | StepType>) => number;
  selectedStepIdx?: number;
};

export const stepTheme = {
  green: {
    lighter: "#DCFAE6",
    light: "#A9EFC5",
    medium: "#17B26A",
    dark: "#085D3A"
  },
  orange: {
    lighter: "#FEE6C7",
    light: "#FECD89",
    medium: "#F78512",
    dark: "#93370D"
  },
  grey: {
    lighter: "#DCFAE6",
    light: "#A9EFC5",
    medium: "#17B26A",
    dark: "#085D3A"
  },
  blue: {
    lighter: "#EAF1FF",
    light: "#A6C3FF",
    medium: "#0F58ED",
    dark: "#0D3B99"
  }
};

const getStepTheme = (theme: keyof typeof stepTheme = "grey") => {
  return stepTheme[ theme ];
};

export const withStepTheme = (theme: keyof typeof stepTheme = "grey") => {
  return {
    "--step-theme-lighter": getStepTheme(theme).lighter,
    "--step-theme-light": getStepTheme(theme).light,
    "--step-theme-medium": getStepTheme(theme).medium,
    "--step-theme-dark": getStepTheme(theme).dark
  } as React.CSSProperties;
};

/** Pre-themed, easier to use by default */
export const RodaFlywheel: React.FC<RodaFlywheelProps> = ({
  flywheel,
  size,
  loading,
  position,
  onStepClick,
  shouldHoverSteps = false,
  stepsProps,
  renderInnerContent,
  stepRingOutlineColor = "white",
  separators = true,
  stepSize,
  selectedStepIdx
}) => {
  const isMobile = useIsMobile();
  const [ flywheelPosition, setFlywheelPosition ] = useState<"centre" | "side" | "huge">(position);
  const [ animating, setAnimating ] = useState(true);
  const { flywheelCycleNotStarted, isFullyLoaded } = useSelectedFlywheel();

  useEffect(() => {
    setAnimating(true);

    if (position !== "centre") {
      setTimeout(() => {
        setFlywheelPosition(position);
        setAnimating(false);
      }, 200);
    } else {
      setFlywheelPosition("centre");

      setTimeout(() => {
        setAnimating(false);
      }, 200);
    }
  }, [ position ]);

  return (
    <>
      <BaseFlywheel
        size={size}
        thickness={2}
        stepQty={flywheel.steps.length}
        stepIndex={selectedStepIdx}
        position={flywheelPosition}
        stroke="#BDCCD4"
        shouldHoverSteps={!animating && shouldHoverSteps}
        renderInnerContent={renderInnerContent}
        renderSeparator={separators ? (
          <div
            style={{
              transform: "rotate(calc(var(--angle) + 80deg))",
              width: size / 8,
              height: size / 8
            }}
          >
            <FiChevronRight
              className="text-[#BDCCD4] [&_*]:[vector-effect:non-scaling-stroke]"
              strokeWidth={1.5}
              style={{
                width: (size / 8) - ((size / 8) * 0.2),
                height: size / 8
              }}
            />
          </div>
        ) : undefined}
      >
        {flywheel.steps?.map((step, i) => {
          const isSelected = selectedStepIdx === i;
          const status: HealthStatus = (flywheelCycleNotStarted || !isFullyLoaded) ? HealthStatus.Waiting : getStepStatus(step) ;
          const handleClick = () => onStepClick?.(step);
          const currentStepSize = stepSize ? stepSize(step) : 7;

          return (
            <BaseFlywheelStep
              key={`base_flywheel_step_${+i}`}
              size={currentStepSize}
              childOffset={isMobile ? 0 : 20}
              onClick={handleClick}
              stepContainerClassname={clsx("[animation-delay:calc(500ms+200ms+var(--index)*100ms)]", flywheel?.steps && !loading ? "animate-in fade-in-0 fill-mode-both duration-1000" : "opacity-0")}
              stepContainerStyle={withStepTheme(getHealthStatusColour(status))}
              circle={{
                strokeColor: "var(--step-theme-lighter)",
                strokeWidth: 5,
                className: "fill-[var(--step-theme-medium)]"
              }}
              ring={{
                offset: 6,
                strokeWidth: 4,
                strokeColor: stepRingOutlineColor,
                fill: "var(--step-theme-light)",
                className: ""
              }}
              selected={isSelected}
              {...stepsProps?.[ i ]}
            >
              {stepsProps?.[ i ]?.children ??
              (
                <button
                  onClick={handleClick}
                  disabled={!isFullyLoaded}
                  style={{
                    transitionProperty: "opacity",
                    transitionTimingFunction: "cubic-bezier(0.7, 0.18, 0.06, 1)",
                    transitionDelay: flywheelPosition !== "centre" ? "600ms" : "0s",
                    transitionDuration: "400ms",
                    ...withStepTheme(getHealthStatusColour(status))
                  }}
                  className={` ${position !== "centre" && "opacity-0"} py-1 flex flex-col transition-all items-center text-body-medium gap-1`}
                >
                  {!isFullyLoaded ? (

                    <LoadingMetrics />
                  ) : (
                    <MetricStatusDots
                      metricStatus={flywheelCycleNotStarted ? HealthStatus.Waiting : undefined}
                      step={step}
                    />
                  )}

                  <p className="font-semibold">{step.alias ?? step.name}</p>

                  {isFullyLoaded ? (
                    <StatusBubble
                      status={status}
                    />
                  ) : (
                    <span
                      className="h-[20px]"
                    />
                  )}
                </button>
              )}
            </BaseFlywheelStep>
          );
        }) as React.ReactElement[]}
      </BaseFlywheel>
    </>
  );
};
