import { zodResolver } from "@hookform/resolvers/zod";
import {
  Currency,
  FlywheelTemplateReportingWindowTimingEnum
} from "@roda/shared/types";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import {
  Input,
  TextArea
} from "~/components/form";
import { MetricCheckInSchema } from "~/components/form/formSchemas";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useCreateMetricUpdate } from "~/hooks/metric-update/use-create-metric-update";
import { useError } from "~/hooks/useError";
import { useIsMobile } from "~/hooks/useIsMobile";
import dayjs from "~/utils/dayjs";
import { getUnitSymbol } from "~/utils/getUnitSymbol";
import { cleanNumberInput } from "~/utils/validation/cleanNumberInput";
import { trimTrailingDots } from "~/utils/validation/trimTrailingDots";

import type { Metric } from "@roda/graphql/genql";
import type { z } from "zod";

interface MetricCheckInPopupProps {
  isOpen: boolean;
  onClose: () => void;
  metric: Metric;
  weekNumber: number;
  weekStart: string;
}

export const MetricCheckInPopup: React.FC<MetricCheckInPopupProps> = ({
  isOpen, metric, onClose, weekNumber, weekStart
}) => {
  const [ checkInRes, checkInReq ] = useCreateMetricUpdate();
  const { flywheel } = useSelectedFlywheel();
  const { assertGraphQLSuccess, handleRodaError } = useError();
  const metricUnitSymbol = getUnitSymbol(metric?.unitTypeLabel, flywheel?.currency as Currency || Currency.GBP);
  const isMobile = useIsMobile();
  const unitDisplayText = useMemo(() => `${metric.unitName} ${metric.reportingWindowTiming !== FlywheelTemplateReportingWindowTimingEnum.QUARTER_TO_DATE ? "this week" : "this quarter to date"}`, [ metric.reportingWindowTiming, metric.unitName ]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<z.infer<typeof MetricCheckInSchema>>({
    resolver: zodResolver(MetricCheckInSchema),
    shouldFocusError: false
  });

  const currentTarget = metric.targets?.find(target => target.isCurrent);

  // Submit handler
  const onSubmit = handleSubmit(fields => {
    // We should always have a current target

    if (currentTarget?.id) {
      checkInReq({
        metricId: +metric.id,
        metricTargetId: +currentTarget.id,
        value: trimTrailingDots(fields.value), // trim dots
        startDate: weekStart,
        notes: fields.notes
      })
        .then(res => {
          assertGraphQLSuccess(res);
          toast.success("Checked in successfully!", { position: isMobile ? "top-center" : "top-right" });
          reset(); // reset form
          onClose(); // close modal
        })
        .catch(e => {
          handleRodaError(e, "An error occurred! Try again later!");
        });
    }
  });

  // Cancel handler, reset form and close modal
  const handleCancel = () => {
    reset();
    onClose();
  };

  return (
    <ConfirmationPopup
      isOpen={isOpen}
      title={metric.name}
      subtitle={`Check in for W.${weekNumber} ${dayjs(weekStart).format("MMMM DD")}`}
      onContinue={onSubmit}
      continueText="Save"
      cancelText="Cancel"
      onCancel={handleCancel}
      loading={checkInRes.fetching}
      continueDisabled={checkInRes.fetching}
    >
      <div>
        <form
          onSubmit={onSubmit}
        >
          <div className="flex flex-col gap-y-3">

            <Input
              {...register("value", {
                onChange: e => {
                  const cleanedValue = cleanNumberInput(e.target.value);

                  setValue("value", cleanedValue);
                }
              })}
              disabled={checkInRes.fetching}
              label={unitDisplayText}
              min={0.01}
              inputMode="numeric"
              hasError={!!errors.value}
              autoComplete="off"
              autoCorrect="off"
              errorMessage={errors.value?.message}
              iconPosition={metricUnitSymbol === "%" ? "right" : "left"}
              iconComponent={<p>{metricUnitSymbol}</p>}
            />

            <TextArea
              {...register("notes")}
              disabled={checkInRes.fetching}
              label="Any notes to add?"
              placeholder="Please insert any comments you would like to share on this week's progress"
              name="notes"
              inputMode="text"
              autoComplete="off"
              autoCorrect="off"
              hasError={!!errors.notes}
              errorMessage={errors.notes?.message}
            />
          </div>

        </form>
      </div>
    </ConfirmationPopup>
  );
};