import { zodResolver } from "@hookform/resolvers/zod";
import {
  useEffect,
  useMemo
} from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import { Input } from "~/components/form";
import { MetricTargetSchema } from "~/components/form/formSchemas";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import type { FlywheelReviewMetricData } from "~/hooks/flywheel/use-get-flywheel-review";
import { useReviewMetric } from "~/hooks/metric/use-review-metric";
import { useError } from "~/hooks/useError";
import dayjs from "~/utils/dayjs";
import { CurrencyIcon } from "~/utils/getCurrencyIcon";
import { getUnitSymbol } from "~/utils/getUnitSymbol";
import { getWeekMetricTarget } from "~/utils/getWeekMetricTarget";
import { cleanNumberInput } from "~/utils/validation/cleanNumberInput";
import { trimTrailingDots } from "~/utils/validation/trimTrailingDots";

import type { Currency } from "@roda/shared/types";
import type { z } from "zod";

interface ReviewMetricTargetPopupProps {
  isOpen: boolean;
  onClose: () => void;
  metric?: FlywheelReviewMetricData;
  subgoalId: number;
}

export const ReviewMetricTargetPopup: React.FC<ReviewMetricTargetPopupProps> = ({
  isOpen, metric, subgoalId, onClose
}) => {
  const [ reviewMetricRes, reviewMetricReq ] = useReviewMetric();
  const { flywheel, subgoalInReview } = useSelectedFlywheel();
  const { currentCompany } = useRodaAdminCompaniesContext();
  const { handleRodaError, assertGraphQLSuccess } = useError();
  const currentReviewTarget = useMemo(() => subgoalInReview && metric ? getWeekMetricTarget(metric.targets, dayjs(subgoalInReview.startDate)) : undefined, [ metric, subgoalInReview ]);
  const metricUnitSymbol = getUnitSymbol(metric?.unitTypeLabel, flywheel?.currency as Currency || CurrencyIcon.GBP);
  const MetricTargetSchemaWithCap = MetricTargetSchema(metric?.cap);
  const currentMetricTarget = metric?.targets?.find(target => target.isNext);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    formState: { errors }
  } = useForm<z.infer<typeof MetricTargetSchemaWithCap>>({
    resolver: zodResolver(MetricTargetSchemaWithCap),
    shouldFocusError: false,
    // Default to the current target
    // TODO: Default this to the latest target for the subgoal we're reviewing
    defaultValues: { target: currentMetricTarget?.target || undefined }
  });

  const watchedTarget = watch("target");
  const targetHasChanged = useMemo(() => !!currentReviewTarget && currentReviewTarget.target === watchedTarget, [ currentReviewTarget, watchedTarget ]);

  // Submit handler
  const onSubmit = handleSubmit(fields => {
    if (flywheel && metric) {
      reviewMetricReq({
        flywheelId: +flywheel?.id,
        subgoalId,
        metricId: +metric.id,
        companyId: currentCompany ? +currentCompany.id : undefined,
        target: trimTrailingDots(fields.target!)
      })
        .then(res => {
          assertGraphQLSuccess(res);
          reset(); // reset form
          onClose(); // close modal
          toast.success("Reviewed target successfully!");
        })
        .catch(e => {
          handleRodaError(e, "An error occurred! Try again later!");
        });
    }
  });

  // Cancel handler, reset form and close modal
  const handleCancel = () => {
    reset();
    onClose();
  };

  // Update the default value when the metric target loads in
  useEffect(() => {
    if (currentReviewTarget) {
      setValue("target", currentReviewTarget.target);
    }
  }, [
    currentReviewTarget,
    getValues,
    metric,
    setValue
  ]);

  return (
    <ConfirmationPopup
      title={metric?.name || ""}
      subtitle="Review your target"
      continueText={targetHasChanged ? "Keep same target" : "Continue"}
      isOpen={isOpen && !!metric}
      onContinue={onSubmit}
      onCancel={handleCancel}
      loading={reviewMetricRes.fetching}
      continueDisabled={reviewMetricRes.fetching}
    >
      <p className="text-sm text-brand-cold-metal-500">Review your target for the upcoming quarter.</p>

      <form>
        <Input
          label={metric?.unitName}
          {...register("target", {
            onChange: e => {
              const cleanedValue = cleanNumberInput(e.target.value);

              setValue("target", cleanedValue);
            }
          })}
          disabled={reviewMetricRes.fetching}
          min={0.01}
          inputMode="numeric"
          autoComplete="off"
          autoCorrect="off"
          errorMessage={errors.target?.message}
          hasError={!!errors.target}
          iconPosition={metricUnitSymbol === "%" ? "right" : "left"}
          iconComponent={<p>{metricUnitSymbol}</p>}
        />
      </form>
    </ConfirmationPopup>
  );
};