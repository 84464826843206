import type { OnboardingStep } from "~/components/onboarding/OnboardingProgressBar";
import type { MetricType } from "~/contexts/CustomiseMetricContext/metric-reducer";
import type { SingleListedFlywheel } from "~/hooks/flywheel";

import type {
  User,
  Company,
  FlywheelGoal,
  Flywheel,
  Step,
  Subgoal
} from "@roda/graphql/genql";
import type { Optional } from "@roda/shared/types";

export interface UserDetails {
  email: string | null;
  firstName: User["firstName"];
  lastName: User["lastName"];
  jobTitle: User["jobTitle"];
}

export interface CompanyDetailsType {
  id?: number | null;
  industryId: Company["industryId"];
  name: Company["name"];
  currency: string;
}

export type FlywheelGoalType = Optional<Pick<FlywheelGoal, "goal" | "achieveBy" | "updateFrequency" | "name" | "unitName" | "unitType" | "unitTypeLabel" | "cap"| "unitDescription" | "latestTotalValue"| "fromDate" > & {
  id?: string | null;
  ownerEmail: string;
  subgoals: Array<Pick<Subgoal, "id" | "goal" | "startDate" |"endDate">> | null;
  flywheelGoalLatestUpdateValue: string;
  checkInSubgoal: Pick<Subgoal, "id" | "goal" | "startDate" |"endDate"> | null;
}>;

export type StepType = Optional<Pick<Step, "id" | "name" | "order" | "flywheelId" | "ownerId" | "alias"> & {
  stepIdx?: number;
  metrics: MetricType[] | null;
  description: string;
  // addStepMode?: boolean;
}>;

export type FlywheelType = Optional<Pick<Flywheel, "id" | "companyId" | "currency" | "flywheelTemplateId" | "name" | "ownerId" | "timeframe" | "updateFrequency" | "setupComplete"> & {
  steps: StepType[] | null
}>;
export interface State {
  step: OnboardingStep;
  user: UserDetails | null;
  company: CompanyDetailsType | null;
  flywheel: FlywheelType | null;
  flywheelGoal: FlywheelGoalType | null;
  invitedUsers: string[];
}

export type TransformActions =
  | { type: "RESET"}
  | { type: "SET_STATE", state: Partial<State>}
  | { type: "SET_STEP", step: OnboardingStep }
  | { type: "UPDATE_USER", user: UserDetails }
  | { type: "UPDATE_COMPANY", company: CompanyDetailsType}
  | { type: "LOAD", flywheel: SingleListedFlywheel}
  | { type: "SET_FLYWHEEL", flywheel: FlywheelType}
  | { type: "SET_FLYWHEEL_GOAL_REPORTING_CYCLE", achieveBy: string}
  | { type: "SET_FLYWHEEL_GOAL", flywheelGoal: FlywheelGoalType}
  | { type: "UPDATE_STEP", stepIdx: number, alias: string | null}
  | { type: "ADD_OR_UPDATE_METRIC", metric: MetricType, stepIdx: number}
  | { type: "REMOVE_METRIC", metric: MetricType, stepIdx: number}
  | { type: "ADD_INVITED_USER", userEmail: string};

export const initialState: State = {
  step: "user_details",
  user: null,
  company: null,
  flywheel: null,
  flywheelGoal: null,
  invitedUsers: []
};

export const onboardingReducer = (prevState: State, action: TransformActions): State => {
  switch (action.type) {
    case "RESET":
      return initialState;
    case "LOAD": {
      let navigateToStep: OnboardingStep = "flywheel_intro";

      if (action.flywheel?.id) {
        // FW setup so go to flywheel goal!
        navigateToStep = "reporting_cycle";
      }

      if (action.flywheel?.latestFlywheelGoal?.achieveBy) {
        // FW setup so go to flywheel goal!
        navigateToStep = "flywheel_goal";
      }

      if (action.flywheel?.latestFlywheelGoal?.goal) {
        // goal setup so go to metrics & targets!
        navigateToStep = "flywheel_goal_progress";
      }

      if (action.flywheel?.latestFlywheelGoal?.subgoals?.length) {
        // goal setup so go to metrics & targets!
        navigateToStep = "metrics_targets";
      }

      return {
        ...prevState,
        flywheel: {
          ...action.flywheel,
          steps: action.flywheel.steps?.map((step, index) => ({
            ...step,
            stepIdx: index,
            metrics: step.metrics?.map(metric => ({
              ownerEmail: metric.owner?.email,
              target: metric.targets?.[ 0 ]?.target,
              ...metric
            }))
          }))
        },
        flywheelGoal: {
          ...action.flywheel.latestFlywheelGoal,
          subgoals: action.flywheel.latestFlywheelGoal?.subgoals?.map(subgoal => ({
            ...subgoal,
            latestUpdate: subgoal.latestUpdate,
            updates: []
          }))
        },
        step: navigateToStep
      };
    }
    case "SET_STATE":
      return {
        ...prevState,
        ...action.state
      };
    case "SET_STEP":
      return {
        ...prevState,
        flywheelGoal: prevState.flywheelGoal,
        step: action.step
      };
    case "UPDATE_USER":
      return {
        ...prevState,
        user: action.user
      };
    case "UPDATE_COMPANY":
      return {
        ...prevState,
        company: action.company
      };
    case "SET_FLYWHEEL":
      return {
        ...prevState,
        flywheel: action.flywheel as FlywheelType
      };
    case "SET_FLYWHEEL_GOAL_REPORTING_CYCLE":
      return {
        ...prevState,
        flywheelGoal: {
          ...prevState.flywheelGoal,
          achieveBy: action.achieveBy
        }
      };
    case "SET_FLYWHEEL_GOAL":
      return {
        ...prevState,
        flywheelGoal: {
          ...prevState.flywheelGoal,
          ...action.flywheelGoal
        }
      };

    case "UPDATE_STEP": {
      const steps = prevState.flywheel?.steps || [];
      const newSteps = [ ...steps ];
      // Find the index of the step with the specified stepIdx
      const stepIdx = steps.findIndex((step, index) => index === action.stepIdx);

      // Set the new alias
      if (stepIdx !== -1) {
        newSteps[ stepIdx ] = {
          ...steps[ stepIdx ],
          alias: action.alias
        };
      }

      return {
        ...prevState,
        flywheel: {
          ...prevState.flywheel,
          steps: newSteps
        }
      };
    }

    case "ADD_OR_UPDATE_METRIC": {
      // Get the targeted step object
      const step = prevState.flywheel?.steps?.find(step => step?.stepIdx === action.stepIdx);
      // Get the step's current metrics
      const stepMetrics = step?.metrics || [];
      // Initialize new step metrics to be a copy of the current metrics
      const newStepMetrics = [ ...stepMetrics ];

      // If metricIdx is defined then replace
      if (action.metric.metricIdx !== undefined) {
        newStepMetrics[ action.metric.metricIdx ] = { ...action.metric };
      } else {
        // Get the new metricIdx and append it to the new metric so we keep track of it
        const newMetricIdx = newStepMetrics.length;

        // If the metric doesn't exist, add the new metric
        newStepMetrics.push({
          ...action.metric,
          metricIdx: newMetricIdx
        });
      }

      // Step including the new metrics
      const newStep = {
        ...step,
        metrics: newStepMetrics
      };

      // Construct steps having the updated step
      const newSteps = prevState.flywheel?.steps?.map(existing => {
        if (existing?.stepIdx === action.stepIdx) {
          return newStep;
        } else {
          return existing;
        }
      });

      return {
        ...prevState,
        flywheel: {
          ...prevState.flywheel,
          steps: newSteps ? [ ...newSteps ] : []
        }
      };
    }
    case "REMOVE_METRIC": {
      // Get the targeted step object
      const step = prevState.flywheel?.steps?.find(step => step?.stepIdx === action.stepIdx);
      // Get the step's current metrics
      const stepMetrics = step?.metrics || [];
      // Get a copy of the step metrics
      const newStepMetrics: MetricType[] = stepMetrics.filter(metric => metric.id !== action.metric.id || metric.metricIdx !== action.metric.metricIdx);

      // Update the metricIdx for every element
      const updatedIdxMetrics = newStepMetrics.map((metric, idx) => {
        return {
          ...metric,
          metricIdx: idx
        };
      });

      // Step including the new metrics
      const newStep = {
        ...step,
        metrics: updatedIdxMetrics
      };

      // Construct steps having the updated step
      const newSteps = prevState.flywheel?.steps?.map(existing => {
        if (existing?.stepIdx === action.stepIdx) {
          return newStep;
        } else {
          return existing;
        }
      });

      return {
        ...prevState,
        flywheel: {
          ...prevState.flywheel,
          steps: newSteps ? [ ...newSteps ] : []
        }
      };
    }

    case "ADD_INVITED_USER": {
      const invitedUsers = [ ...prevState.invitedUsers ];

      invitedUsers.push(action.userEmail);
      const uniqueUsers = Array.from(new Set(invitedUsers));

      return {
        ...prevState,
        invitedUsers: uniqueUsers
      };
    }

    default:
      return prevState;
  }
};