import { zodResolver } from "@hookform/resolvers/zod";
import {
  enumUserRole,
  type User
} from "@roda/graphql/genql";
import {
  FlywheelTemplateUnitTypeLabelEnum,
  ReviewPeriod,
  type FlywheelTemplateJson,
  type FlywheelTemplateUnit
} from "@roda/shared/types";
import {
  useEffect,
  useMemo,
  useState
} from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { Avatar } from "~/components/Avatar";
import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import {
  Input,
  SelectInput
} from "~/components/form";
import { MetricSchema } from "~/components/form/formSchemas";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useCurrentUser } from "~/contexts/UserContext";
import type { GetFlywheelStepData } from "~/hooks/flywheel/use-get-flywheel";
import { useGetFlywheelTemplate } from "~/hooks/flywheel-template";
import { useCreateMetricWithTargetInReview } from "~/hooks/metric/use-create-metric-with-target-in-review";
import { useError } from "~/hooks/useError";
import dayjs from "~/utils/dayjs";
import { getUnitSymbol } from "~/utils/getUnitSymbol";

import type { z } from "zod";

interface AddMetricPopupProps {
  isOpen: boolean;
  onClose: () => void;
  step?: GetFlywheelStepData | null;
  subgoalId: number;
}

export const AddMetricPopup: React.FC<AddMetricPopupProps> = ({
  isOpen, step, onClose
}) => {
  // Context data
  const { user } = useCurrentUser();
  const { currentCompany } = useRodaAdminCompaniesContext();
  const isRodaAdmin = user?.role === enumUserRole.RODA_ADMIN;

  const {
    flywheel, flywheelSubgoals, subgoalInReview, activeFlywheelReview
  } = useSelectedFlywheel();

  // Queries and mutations
  // Fetch the flywheel template
  const [ { data: flywheelTemplateData } ] = useGetFlywheelTemplate(flywheel?.id ? Number(flywheel.id) : undefined, {
    pause: !currentCompany?.id && !user?.companyId,
    requestPolicy: "network-only"
  });

  const [ createMetricInReviewRes, createMetricInReviewReq ] = useCreateMetricWithTargetInReview();
  const { handleRodaError } = useError();
  // Computed data
  const flywheelTemplateInfo: FlywheelTemplateJson | null = useMemo(() => flywheelTemplateData?.getFlywheelTemplate ? JSON.parse(flywheelTemplateData.getFlywheelTemplate.info as string) : null, [ flywheelTemplateData?.getFlywheelTemplate ]);
  const selectedTemplateStep = useMemo(() => flywheelTemplateInfo?.flywheel?.steps?.find(templateStep => templateStep.name === step?.name), [ flywheelTemplateInfo?.flywheel?.steps, step?.name ]);

  // Get the review subgoal idx
  // If it's a next year review this will ALWAYS be the first one
  const reviewSubgoalIdx = useMemo(() => {
    const index = activeFlywheelReview?.reviewPeriod === ReviewPeriod.NEXT_YEAR ? 0 : flywheelSubgoals && subgoalInReview ? flywheelSubgoals.findIndex(subgoal => subgoal.id === subgoalInReview.id) : undefined;

    return index !== -1 ? index : undefined;
  }, [
    activeFlywheelReview?.reviewPeriod,
    flywheelSubgoals,
    subgoalInReview
  ]);

  // The metrics that already exist for this step for the coming quarter
  const existingMetrics = useMemo(() => step?.metrics || [], [ step?.metrics ]);
  // Form option data
  // The metrics from the template
  const suggestedMetrics = useMemo(() => selectedTemplateStep?.metrics || [], [ selectedTemplateStep?.metrics ]);
  // Get the list of units which HAVEN'T been used yet - this is how we tell which metrics can be suggested
  const availableMetricUnits = useMemo(() => suggestedMetrics.flatMap(metric => metric.units).filter(unit => !existingMetrics.find(metric => metric.unitName === unit.name)), [ existingMetrics, suggestedMetrics ]);

  // The subset of available metrics which should be available for the user to choose - excluding
  // existing metrics for this step. We exclude metrics where the unit has already been selected - or the
  // whole metric if all units have been used to create metrics already
  const metricsToSuggestForStep = useMemo(() => suggestedMetrics.map(suggestedMetric => {
    return {
      ...suggestedMetric,
      // Filter the units to only those which haven't been used yet
      units: suggestedMetric.units.filter(unit => availableMetricUnits.find(metricUnit => metricUnit.name === unit.name))
    };
  // Filter to only those with >= 1 available unit
  }).filter(suggestedMetric => !!suggestedMetric.units.length), [ availableMetricUnits, suggestedMetrics ]);

  const activeCompanyUsers = useMemo(() => currentCompany ? currentCompany.activeUsers : user?.company?.activeUsers, [ currentCompany, user?.company?.activeUsers ]);
  const [ selectedMetricUnitOptions, setSelectedMetricUnitOptions ] = useState<FlywheelTemplateUnit[] | undefined>();

  const defaultOwner = useMemo(() => {
    // Roda admins should default to the first user in the company - not themselves!!
    if (isRodaAdmin) {
      if (activeCompanyUsers && activeCompanyUsers.length) return Number(activeCompanyUsers[ 0 ].id);

      return undefined;
    // Everyone else should default to themself
    } else if (user) return Number(user.id);

    return undefined;
  }, [
    activeCompanyUsers,
    isRodaAdmin,
    user
  ]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors }
  } = useForm<z.infer<typeof MetricSchema>>({
    resolver: zodResolver(MetricSchema),
    shouldFocusError: false,
    defaultValues: {
      // Default owner to the current user - or the first user in the list of options for Roda admins
      ownerId: defaultOwner
    }
  });

  const watchedTarget = watch("target");
  const watchedOwnerId = watch("ownerId");
  const watchedMetricName = watch("metricName");
  const watchedUnitName = watch("unitName");
  const watchedUnitTypeLabel = watch("unitTypeLabel");
  const watchedCap = watch("cap");
  const unitIcon = useMemo(() => getUnitSymbol(watchedUnitTypeLabel as FlywheelTemplateUnitTypeLabelEnum, flywheel?.currency), [ flywheel?.currency, watchedUnitTypeLabel ]);

  // Get cap percentage
  const exceedsPercentageMax = useMemo(() => watchedUnitTypeLabel === FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE && watchedCap ? +watchedTarget > watchedCap : false, [
    watchedCap,
    watchedTarget,
    watchedUnitTypeLabel
  ]);

  // Whenever the chosen metric changes, update the list of available units for it
  useEffect(() => {
    if (watchedMetricName) {
      const metric = metricsToSuggestForStep.find(metric => metric.name === watchedMetricName);

      setSelectedMetricUnitOptions(metric?.units);
      // Set defaults for the first unit option
      const defaultUnit = metric?.units.length ? metric.units[ 0 ] : undefined;

      if (defaultUnit) {
        setValue("unitName", defaultUnit.name);
        setValue("unitDisplay", defaultUnit.display as string);
        setValue("unitDescription", defaultUnit.description);
        setValue("unitType", defaultUnit.type);
        setValue("unitTypeLabel", defaultUnit.typeLabel);
        setValue("unitTargetType", defaultUnit.targetType);

        if (defaultUnit.cap && typeof defaultUnit.cap === "number") {
          setValue("cap", defaultUnit.cap || undefined);
        }

        if (defaultUnit.reportingWindowTiming && typeof defaultUnit.reportingWindowTiming === "string") {
          setValue("reportingWindowTiming", defaultUnit.reportingWindowTiming || undefined);
        }

        if (defaultUnit.slug && typeof defaultUnit.slug === "string") {
          setValue("slug", defaultUnit.slug || undefined);
        }
      }
    }
  }, [
    metricsToSuggestForStep,
    setValue,
    watchedMetricName
  ]);

  // Whenever the chosen unit changes, update the unit data
  useEffect(() => {
    if (watchedMetricName && watchedUnitName) {
      const metric = metricsToSuggestForStep.find(metric => metric.name === watchedMetricName);
      const unit = metric?.units.find(unit => unit.name === watchedUnitName);

      if (unit) {
        setValue("unitDisplay", unit.display as string);
        setValue("unitTypeLabel", unit.typeLabel);
        setValue("unitDescription", unit.description);
        setValue("unitTargetType", unit.targetType);
        setValue("unitType", unit.type);

        if (unit.cap && typeof unit.cap === "number") {
          setValue("cap", unit.cap || undefined);
        }

        if (unit.reportingWindowTiming && typeof unit.reportingWindowTiming === "string") {
          setValue("reportingWindowTiming", unit.reportingWindowTiming || undefined);
        }

        if (unit.slug && typeof unit.slug === "string") {
          setValue("slug", unit.slug || undefined);
        }
      }
    }
  }, [
    metricsToSuggestForStep,
    setValue,
    watchedMetricName,
    watchedUnitName
  ]);

  // Submit handler
  const onSubmit = handleSubmit(fields => {
    const companyId = user?.company?.id ?? currentCompany?.id;

    if (!companyId) {
      toast.error("Can't find company");

      return;
    }

    if (flywheel && step && subgoalInReview && !exceedsPercentageMax) {
      // Create the metric in review
      createMetricInReviewReq({
        companyId: +companyId,
        flywheelId: Number(flywheel.id),
        stepId: Number(step.id),
        subgoalId: Number(subgoalInReview.id),
        ownerId: fields.ownerId,
        name: fields.metricName,
        unitDescription: fields.unitDescription,
        unitDisplay: fields.unitDisplay,
        unitName: fields.unitName,
        unitTargetType: fields.unitTargetType,
        unitType: fields.unitType,
        unitTypeLabel: fields.unitTypeLabel,
        cap: fields.cap,
        target: fields.target,
        reportingWindowTiming: fields.reportingWindowTiming,
        slug: fields.slug
      })
        .then(() => {
          reset(); // reset form
          onClose(); // close modal
          toast.success("Metric created!");
        })
        .catch(e => {
          handleRodaError(e, "An error occurred! Try again later!");
        });
    }
  });

  const renderOptionLabel = (userId: string) => {
    const user = activeCompanyUsers?.find(user => user.id === userId);

    if (!user) return "User";

    return (
      <Avatar
        displayName
        px={30}
        user={user as User}
      />

    );
  };

  // Cancel handler, reset form and close modal
  const handleCancel = () => {
    reset();
    onClose();
  };

  return (
    <ConfirmationPopup
      title={`New ${step?.name.toLowerCase() || ""} metric`}
      isOpen={isOpen && !!step}
      continueText="Save"
      cancelText="Discard"
      onContinue={onSubmit}
      onCancel={handleCancel}
      loading={createMetricInReviewRes.fetching}
      continueDisabled={createMetricInReviewRes.fetching}
    >
      <form>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col md:flex-row gap-2">
            <SelectInput
              placeholder="Choose a metric type"
              value={watchedMetricName}
              onValueChange={value => setValue("metricName", value)}
              options={metricsToSuggestForStep.map(metric => metric.name)}
              label="Metric type"
              className="flex-1 min-w-0"
            />

            <SelectInput
              placeholder="Choose a measuring unit"
              value={watch("unitName")}
              // TODO: Update these
              onValueChange={value => setValue("unitName", value)}
              options={selectedMetricUnitOptions ? selectedMetricUnitOptions.map(unit => unit.name) : []}
              optionsDescriptions={selectedMetricUnitOptions ? selectedMetricUnitOptions.map(unit => unit.description) : []}
              label="Measuring unit"
              className="flex-1 min-w-0"
            />
          </div>

          <Input
            label={`Enter the target for Q${reviewSubgoalIdx !== undefined ? reviewSubgoalIdx + 1 : 1} ${dayjs(subgoalInReview?.startDate).format("YYYY")}`}
            {...register("target")}
            hasError={!!errors.target || exceedsPercentageMax}
            errorMessage={exceedsPercentageMax ? `Max of ${watchedCap}% reached` : errors.target?.message}
            min={1}
            autoComplete="off"
            autoCorrect="off"
            inputMode="numeric"
            iconComponent={<p>{unitIcon}</p>}
            iconPosition={unitIcon === "%" ? "right" : "left"}
          />

          {watchedUnitName && watchedUnitName.length && (
            <p className="text-xs text-brand-cold-metal-400">{watchedUnitName}</p>
          )}

          <SelectInput
            label="Who is responsible for updating this metric?"
            placeholder="Choose a metric owner"
            value={String(watchedOwnerId)}
            onValueChange={val => setValue("ownerId", Number(val))}
            options={activeCompanyUsers ? activeCompanyUsers?.map(user => String(user.id)) : []}
            renderOptionLabel={renderOptionLabel}
            errorMessage={errors.ownerId?.message}
          />
        </div>
      </form>
    </ConfirmationPopup>
  );
};