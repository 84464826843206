import { useNavigate } from "react-router-dom";

import { Avatar } from "~/components/Avatar";
import { ProgressBar } from "~/components/flywheel/roda/ProgressBar";
import { routes } from "~/constants/routes";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useRodaSubscription } from "~/contexts/SubscriptionContext";
import { formatGoalNumber } from "~/utils/formatGoalNumber";

import type { User as UserType } from "@roda/graphql/genql";

interface FlywheelInnerContentProps {
  enabled: boolean;
  goalName: string;
  quarter: string;
  quarterProgress: string;
  quarterTotal: string;
  mainGoalProgress: number;
  mainGoalTotal: string;
  mainGoalHealthy: boolean;
  unitIcon: string;
  owner?: UserType | null;
}
export const FlywheelInnerContent: React.FC<FlywheelInnerContentProps> = ({
  goalName,
  enabled,
  quarter,
  quarterProgress,
  quarterTotal,
  mainGoalProgress,
  mainGoalTotal,
  mainGoalHealthy,
  unitIcon,
  owner
}) => {
  const navigate = useNavigate();
  const { flywheelCycleNotStarted, isFullyLoaded } = useSelectedFlywheel();
  const { activeOrTrialing } = useRodaSubscription();
  const { currentCompany } = useRodaAdminCompaniesContext();

  return (
    <button
      id="flywheel-inner-content"
      style={{
        transitionProperty: "opacity",
        transitionDuration: "400ms",
        transitionTimingFunction: "cubic-bezier(0.7, 0.18, 0.06, 1)",
        transitionDelay: !enabled ? "0s" : "600ms"
      }}
      className={` opacity-0 ${enabled ? "opacity-100" : "pointer-events-none opacity-0"} flex flex-col flex-center gap-4 text-brand-cold-metal-900 `}
      onClick={() => {
        if (!activeOrTrialing || !isFullyLoaded) {
          return null;
        }

        navigate(routes.flywheelGoal(currentCompany ? currentCompany.id : undefined), { replace: true });
      }}
    >
      <div className="flex flex-center flex-col pb-2 gap-1">
        <p className="text-xs">{goalName}</p>

        <p className="font-bold text-brand-cold-metal-800 text-2xl md:text-3xl lg:text-4xl xl:text-5xl">{formatGoalNumber(+quarterProgress, unitIcon, {
          stripTrailingZeros: true,
          shouldCompact: true
        })}
        </p>

        <p className="font-bold text-brand-cold-metal-500 text-lg">{`of ${formatGoalNumber(+quarterTotal, unitIcon, {
          stripTrailingZeros: true,
          shouldCompact: true
        })} ${quarter} Target`}
        </p>

        {owner && (
          <Avatar
            user={owner}
            px={20}
            displayName
          />
        )}
      </div>

      <div className="flex flex-col items-center font-semibold">
        <p className="text-brand-cold-metal-600 text-xs">Flywheel goal</p>

        <p className="text-brand-cold-metal-600 text-xs">{`${formatGoalNumber(+mainGoalProgress, unitIcon, {
          stripTrailingZeros: true,
          shouldCompact: true
        })} of ${formatGoalNumber(+mainGoalTotal, unitIcon, {
          stripTrailingZeros: true,
          shouldCompact: true
        })} target`}
        </p>

        <div className="py-3 w-full">
          <ProgressBar
            target={+mainGoalTotal}
            progress={mainGoalProgress}
            isHealthy={mainGoalHealthy}
            neutral={flywheelCycleNotStarted || !mainGoalProgress}
          />
        </div>

      </div>
    </button>
  );
};