import { zodResolver } from "@hookform/resolvers/zod";
import {
  FlywheelTemplateReportingWindowTimingEnum,
  FlywheelTemplateUnitTypeLabelEnum
} from "@roda/shared/types";
import { removeEmojis } from "@roda/shared/utils";
import {
  useEffect,
  useMemo,
  useState
} from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "~/components/Button";
import { CalloutBadge } from "~/components/CalloutBadge";
import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import {
  Input,
  SelectInput
} from "~/components/form";
import { Spacer } from "~/components/Spacer";
import {
  useCustomiseMetricDispatch,
  useCustomiseMetricState
} from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import { CustomiseMetricStage } from "~/contexts/CustomiseMetricContext/metric-reducer";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useIsMobile } from "~/hooks/useIsMobile";

import type { Metric } from "@roda/graphql/genql";

export const Schema = z.object({
  unitName: z.string().trim().min(1, "Required").max(100, "Maximum 100 characters").refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }),
  unitTypeLabel: z.string().trim().min(1, "Required").max(50, "Maximum 50 characters"),
  reportingWindowTiming: z.string().trim().min(1, "Required").max(50, "Maximum 50 characters")
});

export const CustomiseMetricUnits: React.FC = () => {
  const { metric } = useCustomiseMetricState();
  const [ confirmationModal, setConfirmationModal ] = useState(false);
  const setCustomiseMetricDispatch = useCustomiseMetricDispatch();
  const isMobile = useIsMobile();
  const { flywheel } = useSelectedFlywheel();

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors }
  } = useForm<z.infer<typeof Schema>>({
    resolver: zodResolver(Schema),
    defaultValues: {
      unitName: metric?.unitName ?? undefined,
      unitTypeLabel: metric?.unitTypeLabel ?? FlywheelTemplateUnitTypeLabelEnum.COUNT,
      reportingWindowTiming: metric?.reportingWindowTiming ?? FlywheelTemplateReportingWindowTimingEnum.THIS_WEEK
    },
    shouldFocusError: false
  });

  useEffect(() => {
    setCustomiseMetricDispatch({
      type: "SET_SELECTED_METRIC",
      stage: CustomiseMetricStage.CUSTOMISE_UNITS,
      metric: {
        ...metric,
        unitType: "number",
        unitName: metric?.unitName ?? undefined,
        unitTypeLabel: metric?.unitTypeLabel ?? FlywheelTemplateUnitTypeLabelEnum.COUNT,
        reportingWindowTiming: metric?.reportingWindowTiming ?? FlywheelTemplateReportingWindowTimingEnum.THIS_WEEK
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Submit handler
  const onSubmit = handleSubmit(() => {
    setCustomiseMetricDispatch({
      type: "SET_SELECTED_METRIC",
      stage: CustomiseMetricStage.CUSTOMISE_DISPLAYS,
      metric
    });
  });

  const existingMetric: Metric | null = useMemo(() => {
    let existing: Metric | null = null;

    flywheel?.steps?.map(step => step.metrics?.map(m => {
      if (m.id === metric?.id) {
        existing = m;
      }
    }));

    return existing as Metric | null;
  }, [ flywheel, metric?.id ]);

  return (
    <div className="h-full w-full">
      <div className="flex flex-col gap-y-2 mb-6 w-full">
        <p className={`font-bold ${isMobile ? "text-xl" : "text-2xl"}`}>Create metric</p>

        <p className="">Add labels, units & check-in type</p>

      </div>

      <form
        onSubmit={onSubmit}
        className="flex pt-5 flex-col gap-y-5 flex-1"
      >
        <div className="flex flex-col gap-y-1">
          <Input
            {...register("unitName", {
              onChange: e => {
                setCustomiseMetricDispatch({
                  type: "SET_SELECTED_METRIC",
                  stage: CustomiseMetricStage.CUSTOMISE_UNITS,
                  metric: {
                    ...metric,
                    unitName: e.target.value
                  }
                });
              }
            })}
            label="Unit name"
            name="unitName"
            min={1}
            placeholder="E.g. New network leads"
            inputMode="text"
            autoComplete="off"
            autoCorrect="off"
            hasError={!!errors.unitName}
            errorMessage={errors.unitName?.message}
          />

        </div>

        <div className="flex flex-col gap-y-1">
          <SelectInput
            value={watch("unitTypeLabel")}
            onValueChange={val => {
              setValue("unitTypeLabel", val);

              setCustomiseMetricDispatch({
                type: "SET_SELECTED_METRIC",
                stage: CustomiseMetricStage.CUSTOMISE_UNITS,
                metric: {
                  ...metric,
                  unitTypeLabel: val
                }
              });
            }}
            label="Unit type"
            placeholder="Pick a unit type"
            options={[
              FlywheelTemplateUnitTypeLabelEnum.COUNT,
              FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE,
              FlywheelTemplateUnitTypeLabelEnum.CURRENCY
            ]}
            hasError={!!errors.unitTypeLabel}
            errorMessage={errors.unitTypeLabel?.message}
            clearFormError={() => clearErrors("unitTypeLabel")}
            tooltipInfo="The type of unit to check-in"
          />

        </div>

        <div className="flex flex-col gap-y-1">
          <SelectInput
            value={watch("reportingWindowTiming")}
            onValueChange={val => {
              setValue("reportingWindowTiming", val);

              setCustomiseMetricDispatch({
                type: "SET_SELECTED_METRIC",
                stage: CustomiseMetricStage.CUSTOMISE_UNITS,
                metric: {
                  ...metric,
                  reportingWindowTiming: val
                }
              });
            }}
            label="How would you like to report this metric?"
            placeholder="Pick your reporting window"
            options={[ FlywheelTemplateReportingWindowTimingEnum.THIS_WEEK, FlywheelTemplateReportingWindowTimingEnum.QUARTER_TO_DATE ]}
            renderOptionLabel={item => {
              if (item === FlywheelTemplateReportingWindowTimingEnum.THIS_WEEK) {
                return "Just the new value each week";
              } else {
                return "The quarter to date value";
              }
            }}
            hasError={!!errors.reportingWindowTiming}
            errorMessage={errors.reportingWindowTiming?.message}
            clearFormError={() => clearErrors("reportingWindowTiming")}
            tooltipInfo="Will you input the cumulative total or incremental value for each check-in?"
          />

        </div>

        <div className={`flex mt-5 ${isMobile ? "flex-col flex-1" : "flex-row"} justify-between`}>
          {isMobile && (<Spacer />)}

          <div className={`flex gap-x-3 self-start ${isMobile ? "flex-col w-full gap-3" : "flex-row"}`}>

            <Button
              title="Back"
              onClick={() => {
                setCustomiseMetricDispatch({
                  type: "SET_STAGE",
                  stage: CustomiseMetricStage.CUSTOMISE_NAMING
                });
              }}
              className="bg-brand-cold-metal-100 hover:contrast-75 w-full text-brand-cold-metal-900 px-6"
            />

            <Button
              title="Next"
              type="button"
              className={`px-10 ${isMobile ? "order-first" : ""}`}
              onClick={() => {
                if (
                  !!existingMetric?.metricUpdates?.length &&
                  ((existingMetric?.reportingWindowTiming !== metric?.reportingWindowTiming) ||
                  (existingMetric?.unitType !== metric?.unitType) ||
                  (existingMetric?.unitTypeLabel !== metric?.unitTypeLabel))
                ) {
                  setConfirmationModal(true);
                } else {
                  onSubmit();
                }
              }}
            />

          </div>

        </div>

      </form>

      <ConfirmationPopup
        subtitle={(
          <div className="mb-4">
            <CalloutBadge variant="info">
              Review your check-ins
            </CalloutBadge>
          </div>
        )}
        isOpen={confirmationModal}
        title="You already have a check-in history"
        cancelText="Cancel"
        onCancel={() => setConfirmationModal(false)}
        onContinue={onSubmit}
        continueText="Continue"
        text={(
          <div>
            <p className="text-pretty">
              We suggest that you review your previous check-ins to make sure they are consistent with this change.
            </p>

            {metric?.reportingWindowTiming !== existingMetric?.reportingWindowTiming && (

              <p className="text-pretty mt-4">

                {metric?.reportingWindowTiming === FlywheelTemplateReportingWindowTimingEnum.QUARTER_TO_DATE ? "You are changing from weekly check-ins to QTD. Your previous check-ins are on a weekly basis, and we recommend you edit these to reflect this change." : "You are changing from QTD check-ins to weekly. Your previous check-ins are on a QTD basis, and we recommend you edit these to reflect this change."}

              </p>
            )}
          </div>
        )}
      />
    </div>
  );
};