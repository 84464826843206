import { ReportingCycle } from "~/components/onboarding/content/ReportingCycle";
import type {
  OnboardingStep,
  ChangeStep
} from "~/components/onboarding/OnboardingProgressBar";
import { SelectedStepProvider } from "~/contexts/SelectedStepContext";

import {
  CompanyDetails,
  UserDetails,
  FlywheelGoal,
  FlywheelGoalProgress,
  FlywheelIntro,
  MetricsAndTargets
} from "./content";

export interface OnboardingContentProps {
  currentStep: OnboardingStep;
  changeStep: ChangeStep;
  createFlywheelMode?: boolean;
}

export interface OnboardingContentStepProps {
  onNextStep: () => void;
  onBack?: () => void;
  changeStep: ChangeStep;
  createFlywheelMode?: boolean;
}

interface Content {
  title: string;
  subTitle: string;
}

export const CONTENT: Record<OnboardingStep, Content> = {
  user_details: {
    title: "Welcome to Roda",
    subTitle: "Tell us a few details about yourself to get started"
  },
  company_details: {
    title: "Your organisation",
    subTitle: "Tell us about some key organisation details"
  },
  flywheel_intro: {
    title: "Introducing the flywheel",
    subTitle: "Let's explore the key features of a flywheel"
  },
  reporting_cycle: {
    title: "Set up your reporting cycle",
    subTitle: "A flywheel cycle runs for a year. Setting your cycle completion date tells us when you want your typical cycle to run from."
  },
  flywheel_goal: {
    title: "Setting a goal",
    subTitle: "Let's set up a goal for your organisation's flywheel"
  },
  flywheel_goal_progress: {
    title: "Progress towards goal",
    subTitle: "Let us know how much progress you have made since your flywheel cycle started"
  },
  metrics_targets: {
    title: "Steps and metrics",
    subTitle: "Let's set up your quarterly metrics for each step"
  }
};

export const OnboardingContent: React.FC<OnboardingContentProps> = ({
  currentStep, changeStep, createFlywheelMode
}) => {
  return (
    <>
      <div className="flex flex-col flex-1">

        {currentStep === "user_details" && (
          <UserDetails
            onNextStep={() => changeStep("company_details")}
            changeStep={changeStep}
          />
        )}

        {currentStep === "company_details" && (
          <CompanyDetails
            onNextStep={() => changeStep("flywheel_intro")}
            changeStep={changeStep}
            onBack={() => changeStep("user_details")}
          />
        )}

        {currentStep === "flywheel_intro" && (
          <FlywheelIntro
            onNextStep={() => changeStep("reporting_cycle")}
            changeStep={changeStep}
            onBack={!createFlywheelMode ? () => changeStep("company_details") : undefined}
            createFlywheelMode={createFlywheelMode}
            overrideTitle={createFlywheelMode ? "Set up a new flywheel" : undefined}
          />
        )}

        {currentStep === "reporting_cycle" && (
          <ReportingCycle
            onNextStep={() => changeStep("flywheel_goal")}
            changeStep={changeStep}
            onBack={() => changeStep("flywheel_intro")}
            createFlywheelMode={createFlywheelMode}
          />
        )}

        {currentStep === "flywheel_goal" && (
          <FlywheelGoal
            onNextStep={() => changeStep("flywheel_goal_progress")}
            changeStep={changeStep}
            onBack={() => changeStep("reporting_cycle")}
            createFlywheelMode={createFlywheelMode}
          />
        )}

        {currentStep === "flywheel_goal_progress" && (
          <FlywheelGoalProgress
            onNextStep={() => changeStep("metrics_targets")}
            changeStep={changeStep}
            onBack={() => changeStep("flywheel_goal")}
            createFlywheelMode={createFlywheelMode}
          />
        )}

        {currentStep === "metrics_targets" && (
          <SelectedStepProvider>
            <MetricsAndTargets
              onNextStep={() => null}
              changeStep={changeStep}
              onBack={() => changeStep("flywheel_goal_progress")}
              createFlywheelMode={createFlywheelMode}
            />
          </SelectedStepProvider>
        )}
      </div>
    </>

  );
};