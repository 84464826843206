import dayjs from "dayjs";
import {
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import toast from "react-hot-toast";
import {
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom";
import { z } from "zod";

import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import { MetricPreview } from "~/components/customise-metric/MetricPreview";
import { SelectCustomiseMetric } from "~/components/customise-metric/SelectCustomiseMetric";
import { ReviewContentWrapper } from "~/components/review/ReviewContentWrapper";
import { routes } from "~/constants/routes";
import { useCustomiseMetricState } from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useSideNavigation } from "~/contexts/SideNavigationContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useCreateMetricWithTarget } from "~/hooks/metric";
import { useMetricToggleActive } from "~/hooks/metric/use-metric-toggle-active";
import { useUpdateMetric } from "~/hooks/metric/use-update-metric";
import { useError } from "~/hooks/useError";
import { useIsMobile } from "~/hooks/useIsMobile";

import type { Metric } from "@roda/graphql/genql";

export const MetricSchema = z.object({
  ownerEmail: z.string().trim().min(1, "Required").email().max(100, "Maximum 100 characters"),
  target: z.string().trim().min(1, "Required").max(40, "Maximum 40 digits").regex(/^[\d.]+$/, "Invalid number")
});

export const CustomiseMetricScreen: React.FC = () => {
  const { metric, step } = useCustomiseMetricState();
  const { flywheel } = useSelectedFlywheel();
  const isMobile = useIsMobile();
  const { user } = useCurrentUser();
  const { currentCompany } = useRodaAdminCompaniesContext();
  const navigate = useNavigate();
  const params = useParams() as { stepId: string, metricId?: string, companyId?: string };
  const { setHideMainSideNav } = useSideNavigation();
  const [ { fetching: updateMetricFetching }, updateMetricRequest ] = useUpdateMetric();
  const [ { fetching: createMetricFetching }, createMetricRequest ] = useCreateMetricWithTarget();
  const [ { fetching: toggleMetricFetching }, toggleMetricActiveRequest ] = useMetricToggleActive();
  const { assertGraphQLSuccess, handleRodaError } = useError();
  const [ confirmationModal, setConfirmationModal ] = useState(false);

  const existingMetric: Metric | null = useMemo(() => {
    let existing: Metric | null = null;

    flywheel?.steps?.map(step => step.metrics?.map(m => {
      if (m.id === metric?.id) {
        existing = m;
      }
    }));

    return existing as Metric | null;
  }, [ flywheel, metric?.id ]);

  const onUpdateMetric = useCallback(() => {
    setConfirmationModal(false);

    if (!metric) {
      toast.error("Missing metric info");

      return;
    }

    if (metric?.id) {
      updateMetricRequest({
        id: +metric.id,
        name: metric.name,
        ownerEmail: metric.ownerEmail,
        target: metric.target,
        unitName: metric.unitName,
        unitDisplay: metric.unitDisplay,
        unitTypeLabel: metric.unitTypeLabel,
        unitType: metric.unitType,
        unitDescription: metric.unitDescription,
        unitTargetType: metric.unitTargetType,
        cap: metric.cap,
        reportingWindowTiming: metric.reportingWindowTiming
      }).then(res => {
        assertGraphQLSuccess(res);

        if (res.data?.updateMetric) {
          navigate(routes.stepMetric(params.stepId, res.data.updateMetric.id, params.companyId));
        }
      }).catch(e => {
        handleRodaError(e, "An error occurred! Try again later!");
      });
    } else {
      if (!step?.id) {
        toast.error("Can't find step");

        return;
      }

      const companyId = user?.company?.id ?? currentCompany?.id;

      if (!companyId) {
        toast.error("Can't find company");

        return;
      }
      const userEmail = metric.ownerEmail || user?.email;

      if (!userEmail) {
        toast.error("Please add an owner");

        return;
      }

      createMetricRequest({
        name: metric.name!,
        companyId: +companyId,
        ownerEmail: userEmail,
        target: metric.target!,
        unitName: metric.unitName!,
        unitDisplay: metric.unitDisplay!,
        unitTypeLabel: metric.unitTypeLabel!,
        unitType: metric.unitType!,
        unitDescription: metric.unitDescription!,
        unitTargetType: metric.unitTargetType!,
        cap: metric.cap,
        fromDate: dayjs(flywheel?.latestFlywheelGoal?.achieveBy).subtract(1, "year").format("YYYY-MM-DD"),
        stepId: +step.id,
        reportingWindowTiming: metric.reportingWindowTiming
      }).then(res => {
        assertGraphQLSuccess(res);

        if (res.data?.createMetricWithTarget) {
          navigate(routes.step(params.stepId, params.companyId));
        }
      }).catch(e => {
        handleRodaError(e, "An error occurred! Try again later!");
      });
    }
  }, [
    metric,
    updateMetricRequest,
    assertGraphQLSuccess,
    navigate,
    params,
    handleRodaError,
    step,
    user?.company?.id,
    user?.email,
    currentCompany?.id,
    createMetricRequest,
    flywheel?.latestFlywheelGoal?.achieveBy
  ]);

  const onDeleteMetric = useCallback(() => {
    if (metric?.id) {
      toggleMetricActiveRequest({
        metricId: +metric.id,
        disabled: true
      }).then(res => {
        assertGraphQLSuccess(res);

        if (res.data?.toggleActive) {
          navigate(routes.step(params.stepId, params.companyId));
        }
      }).catch(e => {
        handleRodaError(e, "An error occurred! Try again later!");
      });
    }
  }, [
    metric,
    toggleMetricActiveRequest,
    assertGraphQLSuccess,
    navigate,
    params,
    handleRodaError
  ]);

  // On render of this page - always hide the main side nav
  useEffect(() => {
    setHideMainSideNav(true);
  }, [ setHideMainSideNav ]);

  if (!step && !metric) {
    if (params.metricId) {
      return (
        <Navigate
          replace
          to={routes.stepMetric(params.stepId, params.metricId, params.companyId)}
        />
      );
    } else {
      return (
        <Navigate to={routes.dashboard(params.companyId)} />
      );
    }
  }

  return (
    <>
      <ReviewContentWrapper
        leftContent={(
          <SelectCustomiseMetric
            onSave={() => {
              const existingTarget = existingMetric?.targets && existingMetric?.targets[ existingMetric?.targets?.length - 1 ].target;

              if (!existingMetric?.isCheckInDue && (existingTarget && (metric?.target !== existingTarget))) {
                setConfirmationModal(true);
              } else {
                onUpdateMetric();
              }
            }}
            fetching={updateMetricFetching || toggleMetricFetching || createMetricFetching}
            allowSelectOrCreate={!metric?.id}
            onDelete={onDeleteMetric}
            flywheelTemplateId={flywheel?.flywheelTemplateId}
          />
        )}
        rightContent={(
          <div
            className={`relative flex-1 grid place-items-center ${isMobile ? "h-full max-h-[1000px] w-full max-w-[1000px] overflow-x-visible pb-10" : "h-screen overflow-visible"}`}
          >
            <MetricPreview metric={metric} />
          </div>
        )}
      />

      <ConfirmationPopup
        isOpen={confirmationModal}
        title="Changing your target"
        cancelText="Cancel"
        onCancel={() => setConfirmationModal(false)}
        onContinue={onUpdateMetric}
        continueText="Continue"
        text={(
          <div>
            <p className="text-pretty">
              As you have already checked-in for this week, we'll change your target for next week onwards.
            </p>
          </div>
        )}
      />
    </>
  );
};