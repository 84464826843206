import {
  Metric,
  ReducedMetric
} from "~/fragments/metric";

import type { StepGenqlSelection } from "@roda/graphql/genql";

export const Step: StepGenqlSelection = {
  id: true,
  name: true,
  order: true,
  ownerId: true,
  alias: true,
  metrics: Metric
};

export const ReducedStep: StepGenqlSelection = {
  id: true,
  name: true,
  order: true,
  ownerId: true,
  alias: true,
  metrics: {
    id: true,
    name: true,
    owner: {
      id: true,
      firstName: true,
      lastName: true,
      email: true,
      avatarS3Key: true
    },
    stepId: true,
    unitName: true,
    unitType: true,
    unitTypeLabel: true,
    unitDisplay: true,
    unitDescription: true,
    unitTargetType: true,
    cap: true,
    reportingWindowTiming: true,
    slug: true,
    isCheckInDue: true,
    isReviewDue: true,
    isHealthy: true,
    fromDate: true,
    toDate: true
  }
};
