import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { AddMetric } from "~/components/customise-metric/AddMetric";
import { CustomiseMetricDisplay } from "~/components/customise-metric/CustomiseMetricDisplay";
import { CustomiseMetricNaming } from "~/components/customise-metric/CustomiseMetricNaming";
import { CustomiseMetricUnits } from "~/components/customise-metric/CustomiseMetricUnits";
import { ReviewMetricTargetOwner } from "~/components/customise-metric/ReviewMetricTargetOwner";
import { routes } from "~/constants/routes";
import {
  useCustomiseMetricDispatch,
  useCustomiseMetricState
} from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import {
  CustomiseMetricStage,
  type MetricType
} from "~/contexts/CustomiseMetricContext/metric-reducer";
import { getTemplatedSteps } from "~/utils/getFlywheelTemplateInfo";

interface SelectCustomiseMetricProps {
  flywheelTemplateId?: number | null;
  fetching?: boolean;
  onSave: (metric: MetricType) => void;
  onDelete: (metric: MetricType) => void;
  allowSelectOrCreate?: boolean;
}

export const SelectCustomiseMetric: React.FC<SelectCustomiseMetricProps> = ({
  flywheelTemplateId, onSave, onDelete, fetching, allowSelectOrCreate = true
}) => {
  const templateSteps = useMemo(() => {
    return getTemplatedSteps({ flywheelTemplateId });
  }, [ flywheelTemplateId ]);

  const { stage, metric } = useCustomiseMetricState();
  const dispatch = useCustomiseMetricDispatch();
  const navigate = useNavigate();

  switch (stage) {
    case CustomiseMetricStage.SELECT_OR_CREATE:

      return (
        <AddMetric
          templateSteps={templateSteps}
          flywheelTemplateId={flywheelTemplateId}
        />
      );
    case CustomiseMetricStage.REVIEW:

      return (
        <ReviewMetricTargetOwner
          onSave={onSave}
          onBack={() => {
            if (allowSelectOrCreate) {
              if (metric?.metricIdx !== undefined) {
                dispatch({ type: "RESET" });
              } else {
                if (metric?.slug) {
                  // this is a selected metric, so back to selection
                  dispatch({
                    type: "SET_STAGE",
                    stage: CustomiseMetricStage.SELECT_OR_CREATE
                  });
                } else {
                  // if no slug, this is a custom metric, so take back to customise flow
                  dispatch({
                    type: "SET_STAGE",
                    stage: CustomiseMetricStage.CUSTOMISE_DISPLAYS
                  });
                }
              }
            } else {
              if (metric?.stepId && metric?.id) {
                navigate(routes.stepMetric(metric?.stepId?.toString(), metric?.id));
              }
            }
          }}
          onDelete={onDelete}
          fetching={fetching}
        />
      );
    case CustomiseMetricStage.CUSTOMISE_NAMING:
      return (
        <CustomiseMetricNaming />
      );
    case CustomiseMetricStage.CUSTOMISE_UNITS:
      return (
        <CustomiseMetricUnits />
      );
    case CustomiseMetricStage.CUSTOMISE_DISPLAYS:
      return (
        <CustomiseMetricDisplay />
      );

    default:
      return null;
  }
};