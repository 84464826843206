import { zodResolver } from "@hookform/resolvers/zod";
import { removeEmojis } from "@roda/shared/utils";
import dayjs from "dayjs";
import {
  useState,
  useMemo
} from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "~/components/Button";
import { CalloutBadge } from "~/components/CalloutBadge";
import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import { MetricCard } from "~/components/customise-metric/MetricCard";
import { Input } from "~/components/form";
import { Icon } from "~/components/Icon";
import { NoWrap } from "~/components/NoWrap";
import { Spacer } from "~/components/Spacer";
import { Loading } from "~/components/Spinner";
import {
  useCustomiseMetricDispatch,
  useCustomiseMetricState
} from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import {
  CustomiseMetricStage,
  type MetricType
} from "~/contexts/CustomiseMetricContext/metric-reducer";
import {
  useOnboardingDispatch,
  useOnboardingState
} from "~/contexts/OnboardingContext/OnboardingContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useIsMobile } from "~/hooks/useIsMobile";
import { getMetricUnitInfoTitle } from "~/utils/getMetricUnitInfoTitle";
import { getUnitSymbol } from "~/utils/getUnitSymbol";
import { cleanNumberInput } from "~/utils/validation/cleanNumberInput";

import type { Currency } from "@roda/graphql/genql";
import type { FlywheelTemplateUnitTypeLabelEnum } from "@roda/shared/types";

interface Props {
  onSave: (metric: MetricType) => void;
  onDelete: (metric: MetricType) => void;
  onBack?: () => void;
  fetching?: boolean;
}

export const MetricSchema = z.object({
  ownerEmail: z.string().trim().min(1, "Required").email().max(100, "Maximum 100 characters").refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }),
  target: z.string().trim().min(1, "Required").max(40, "Maximum 40 digits").regex(/^[\d.]+$/, "Invalid number")
});

export const ReviewMetricTargetOwner: React.FC<Props> = ({
  onSave, onDelete, fetching, onBack
}) => {
  const { metric } = useCustomiseMetricState();
  const setCustomiseMetricDispatch = useCustomiseMetricDispatch();
  const setOnboardingDispatch = useOnboardingDispatch();
  const { user: currentUser } = useCurrentUser();
  const [ deleteConfirmation, setDeleteConfirmation ] = useState(false);

  const {
    company, invitedUsers, flywheelGoal
  } = useOnboardingState();

  const [ showOwnerConfirmation, setShowOwnerConfirmation ] = useState(false);
  const unitIcon = useMemo(() => getUnitSymbol(metric?.unitTypeLabel as FlywheelTemplateUnitTypeLabelEnum, company?.currency as Currency), [ company?.currency, metric?.unitTypeLabel ]);
  // Get the number of the current metric - if it's an existing metric use its index + 1, if not use the length of the step's metrics + 1.
  const isMobile = useIsMobile();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<z.infer<typeof MetricSchema>>({
    resolver: zodResolver(MetricSchema),
    defaultValues: {
      ownerEmail: metric?.ownerEmail ?? currentUser?.email ?? undefined,
      target: metric?.target
    },
    shouldFocusError: false
  });

  const watchedOwnerEmail = watch("ownerEmail");
  const alreadyInvited = useMemo(() => invitedUsers.find(userEmail => userEmail === watchedOwnerEmail), [ invitedUsers, watchedOwnerEmail ]);
  const watchedTarget = watch("target");

  // Cap values that have a cap set
  const exceedsCap = useMemo(() => {
    return metric?.cap ? +watchedTarget > metric?.cap : false;
  }, [ metric?.cap, watchedTarget ]);

  /**
   * Handler for setting target in the metric in state and form
   * @param target
   */
  const handleAddTarget = (target: string) => {
    setValue("target", target);

    setCustomiseMetricDispatch({
      type: "SET_SELECTED_METRIC",
      stage: CustomiseMetricStage.REVIEW,
      metric: {
        ...metric,
        target
      }
    });
  };

  // Submit handler
  const onSubmit = handleSubmit(fields => {
    onSave({
      id: metric?.id,
      name: metric?.name,
      ownerEmail: metric?.ownerEmail || currentUser?.email || undefined,
      target: fields.target,
      unitName: metric?.unitName,
      unitDisplay: metric?.unitDisplay,
      unitTypeLabel: metric?.unitTypeLabel,
      unitType: metric?.unitType, // use the suggested unitType
      metricIdx: metric?.metricIdx,
      unitDescription: metric?.unitDescription, // use the suggested description
      unitTargetType: metric?.unitTargetType, // use the suggested target type,
      fromDate: dayjs(flywheelGoal?.achieveBy).subtract(1, "year").format("YYYY-MM-DD"), // start of the flywheel
      cap: metric?.cap, // use the suggested cap
      reportingWindowTiming: metric?.reportingWindowTiming, // use the suggested reportingWindowTiming
      slug: metric?.slug // use the suggested slug
    });

    // If current user is not the owner and they haven't been invited already add user to invitedUsers array of onboarding state
    if (fields.ownerEmail !== currentUser?.email && !alreadyInvited) {
      setOnboardingDispatch({
        type: "ADD_INVITED_USER",
        userEmail: fields.ownerEmail
      });
    }
  });

  const fallbackCurrentUserEmail = (cb: () => void) => {
    let val = watchedOwnerEmail;

    if (!val?.trim()) {
      // default to the currentUser email if none provided
      // (lets us display a nicer placeholder)
      val = currentUser?.email || "";

      setCustomiseMetricDispatch({
        type: "SET_SELECTED_METRIC",
        stage: CustomiseMetricStage.REVIEW,
        metric: {
          ...metric,
          ownerEmail: val
        }
      });

      setValue("ownerEmail", val, {
        shouldDirty: true,
        shouldTouch: true
      });
    }

    cb();
  };

  return (
    <div className="h-full">
      <div className="flex flex-col gap-y-2 mb-6 w-full">
        <p className={`font-bold ${isMobile ? "text-xl" : "text-2xl"}`}>Edit metric</p>
      </div>

      {metric && (
        <MetricCard
          onClick={() => setCustomiseMetricDispatch({
            metric,
            type: "SET_SELECTED_METRIC",
            stage: CustomiseMetricStage.CUSTOMISE_NAMING
          })}
          metric={metric}
        />
      )}

      <form
        onSubmit={onSubmit}
        className="flex pt-5 flex-col gap-y-5 flex-1"
      >
        <div className="flex flex-col gap-y-1">
          <Input
            {...register("target", {
              onChange: e => {
                const cleanedValue = cleanNumberInput(e.target.value);

                setValue("target", cleanedValue);
                handleAddTarget(cleanedValue);
              }
            })}
            label="Enter a target for this quarter"
            name="target"
            min={1}
            autoFocus
            autoComplete="off"
            autoCorrect="off"
            inputMode="numeric"
            iconPosition={unitIcon === "%" ? "right" : "left"}
            iconComponent={<p>{unitIcon}</p>}
            hasError={!!errors.target || exceedsCap}
            errorMessage={exceedsCap ? `Max value of ${unitIcon}${metric?.cap}` : errors.target?.message}
          />

          {metric?.name && metric.unitTypeLabel && (
            <p className="text-sm text-brand-cold-metal-500">{getMetricUnitInfoTitle({
              metricName: metric?.name,
              metricUnitTypeLabel: metric.unitTypeLabel
            })}
            </p>
          )}
        </div>

        <Input
          {...register("ownerEmail")}
          label="Who is responsible for delivering this metric and providing weekly updates on progress?"
          name="ownerEmail"
          type="email"
          inputMode="email"
          autoComplete="off"
          autoCorrect="off"
          hasError={!!errors.ownerEmail}
          errorMessage={errors.ownerEmail?.message}
          suggestions={invitedUsers}
          onBlur={e => {
            setCustomiseMetricDispatch({
              type: "SET_SELECTED_METRIC",
              stage: CustomiseMetricStage.REVIEW,
              metric: {
                ...metric,
                ownerEmail: e.target.value
              }
            });
          }}
          onSuggestionSelect={val => setValue("ownerEmail", val, {
            shouldDirty: true,
            shouldTouch: true
          })}
          placeholder={currentUser?.email ? `${currentUser.email} (You)` : "Enter an email"}
        />

        <div className={`flex mt-5 ${isMobile ? "flex-col flex-1" : "flex-row"} justify-between`}>
          {isMobile && (<Spacer />)}

          <div className={`flex gap-x-3 self-start ${isMobile ? "flex-col w-full gap-3" : "flex-row items-center"}`}>

            <Button
              title="Back"
              onClick={onBack}
              disabled={fetching}
              className="bg-brand-cold-metal-100 hover:contrast-75 w-full text-brand-cold-metal-900 px-6"
            />

            <Button
              title="Save"
              type="button"
              disabled={exceedsCap || fetching}
              className={`px-10 ${isMobile ? "order-first" : ""}`}
              onClick={() => {
                if (watchedOwnerEmail && watchedOwnerEmail !== currentUser?.email && !alreadyInvited) {
                  setShowOwnerConfirmation(true);
                } else {
                  fallbackCurrentUserEmail(() => onSubmit());
                }
              }}
            />

            <Button
              title="Delete permanently"
              disabled={fetching}
              className="bg-white text-black justify-center items-center w-full min-w-fit pl-0"
              onClick={() => {
                if (metric?.id) {
                  setDeleteConfirmation(true);
                } else {
                  onDelete(metric!);
                }
              }}
              iconComponent={<Icon.Bin />}
            />

            {fetching && (
              <Loading.Spinner />
            )}

          </div>

        </div>

      </form>

      {/* Owner Email verification modal */}
      {metric && (
        <ConfirmationPopup
          subtitle={(
            <div className="mb-4">
              <CalloutBadge variant="warning" />
            </div>
          )}
          isOpen={deleteConfirmation}
          title="Deactivate this metric?"
          cancelText="Cancel"
          onCancel={() => setDeleteConfirmation(false)}
          onContinue={() => onDelete(metric)}
          continueText="Yes, delete"
          continueDisabled={fetching}
          continueButtonClassName="bg-brand-error-red-500 hover:text-white"
          loading={fetching}
          text={(
            <p className="text-pretty">
              Are you sure you want to delete this metric? This action is irreversible.
            </p>
          )}
        />
      )}

      <ConfirmationPopup
        isOpen={showOwnerConfirmation}
        onContinue={() => {
          fallbackCurrentUserEmail(() => onSubmit());
        }}
        continueText="Okay"
        loading={fetching}
        onCancel={() => setShowOwnerConfirmation(false)}
        title="User will be invited"
        text={(
          <>
            This will invite
            {" "}

            <NoWrap
              wrap
              className="bg-gray-300/50 box-decoration-clone rounded px-0.5"
            >
              {getValues("ownerEmail")}
            </NoWrap>

            {" "}
            to your organisation. When they accept your invitation, they will be responsible for posting updates for this metric.
          </>
        )}
        iconComponent={(
          <Icon.MailOpen
            size={28}
            className="text-brand-cold-metal-700"
          />
        )}
      />
    </div>
  );
};