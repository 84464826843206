import { zodResolver } from "@hookform/resolvers/zod";
import { enumUserRole } from "@roda/graphql/genql";
import {
  ReviewPeriod,
  flywheelGoalUnits,
  FlywheelGoalEnum,
  FlywheelTemplateUnitTypeLabelEnum,
  availableFlywheelTemplates
} from "@roda/shared/types";
import {
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button } from "~/components/Button";
import {
  Input,
  SelectInput
} from "~/components/form";
import { ReviewFlywheelGoalSchema } from "~/components/form/formSchemas";
import { NextQuarterReviewGoalTimeline } from "~/components/review/NextQuarterReviewGoalTimeline";
import { NextYearReviewGoalTimeline } from "~/components/review/NextYearReviewGoalTimeline";
import { ReviewContentWrapper } from "~/components/review/ReviewContentWrapper";
import { routes } from "~/constants/routes";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useSideNavigation } from "~/contexts/SideNavigationContext";
import { useCurrentUser } from "~/contexts/UserContext";
import {
  useCreateFlywheelGoalAndSubgoals,
  useUpdateFlywheelGoal,
  useUpdateFlywheelGoalAndSubgoals
} from "~/hooks/flywheel-goal";
import { useUpdateSubgoal } from "~/hooks/subgoal/use-update-subgoal";
import { useError } from "~/hooks/useError";
import { useIsMobile } from "~/hooks/useIsMobile";
import { breakDownSubgoals } from "~/utils/breakDownSubgoals";
import { constructUtcDate } from "~/utils/dates/constructUtcDate";
import dayjs from "~/utils/dayjs";
import { getUnitSymbol } from "~/utils/getUnitSymbol";
import { cleanNumberInput } from "~/utils/validation/cleanNumberInput";

import type { CreateSubgoalType } from "@roda/core/flywheel-goal/createFlywheelGoalAndSubgoals";
import type { FlywheelGoalUnit } from "@roda/shared/types";
import type { z } from "zod";

export const ReviewFlywheelGoal = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { handleRodaError } = useError();
  const { setHideMainSideNav } = useSideNavigation();
  const { currentCompany } = useRodaAdminCompaniesContext();

  const {
    flywheel, flywheelSubgoals, checkInSubgoal, subgoalInReview, refetchFlywheel, activeFlywheelReview
  } = useSelectedFlywheel();

  const { user } = useCurrentUser();
  const [ updateFlywheelGoalRes, updateFlywheelGoalReq ] = useUpdateFlywheelGoal();
  const [ updateFlywheelGoalAndSubgoalsRes, updateFlywheelGoalAndSubgoalsReq ] = useUpdateFlywheelGoalAndSubgoals();
  const [ updateSubgoalRes, updateSubgoalReq ] = useUpdateSubgoal();
  const [ createFlywheelGoalRes, createFlywheelGoalReq ] = useCreateFlywheelGoalAndSubgoals();
  const flywheelGoalInReview = useMemo(() => activeFlywheelReview?.flywheelGoal, [ activeFlywheelReview ]);
  const flywheelGoal = useMemo(() => flywheel?.latestFlywheelGoal, [ flywheel?.latestFlywheelGoal ]);
  const isNextYearReview = useMemo(() => activeFlywheelReview?.reviewPeriod === ReviewPeriod.NEXT_YEAR, [ activeFlywheelReview?.reviewPeriod ]);
  const isRodaAdmin = user?.role === enumUserRole.RODA_ADMIN;
  const isAdmin = user?.role === enumUserRole.ADMIN;
  const isFlywheelGoalOwner = flywheel?.latestFlywheelGoal?.ownerId == user?.id;
  // TODO: Refactor for year review to check upcoming metric owners
  const isMetricOwner = user?.isMetricOwner;
  const [ selectedUnit, setSelectedUnit ] = useState<FlywheelGoalUnit | undefined>();

  // Construct the achieveByDate
  const newFlywheelGoalAchieveBy = useMemo(() =>
    flywheelGoalInReview ?
      // If we already have a flywheelGoalInReview get its achieveBy date.
      dayjs(flywheelGoalInReview.achieveBy) :
      // Otherwise we need to set it to a year later than the current flywheel goal
      dayjs(flywheel?.latestFlywheelGoal?.achieveBy).add(1, "year"), [ flywheel?.latestFlywheelGoal?.achieveBy, flywheelGoalInReview ]);

  const getFlywheelGoalTypeLabel = () => {
    let flywheelGoalTypeLabel = FlywheelTemplateUnitTypeLabelEnum.CURRENCY;

    if (flywheel?.flywheelTemplateId && activeFlywheelReview?.flywheelGoal) {
      // Find the right unit object based on the name that was picked
      const unit: FlywheelGoalUnit = {
        cap: activeFlywheelReview?.flywheelGoal?.cap,
        name: activeFlywheelReview?.flywheelGoal?.name,
        unitDescription: activeFlywheelReview?.flywheelGoal?.unitDescription,
        unitTypeLabel: activeFlywheelReview?.flywheelGoal?.unitTypeLabel,
        unitDisplay: activeFlywheelReview?.flywheelGoal?.unitDisplay,
        unitName: activeFlywheelReview?.flywheelGoal?.unitDisplay
      };

      setSelectedUnit(unit); // also update in state

      if (unit?.unitTypeLabel)
        flywheelGoalTypeLabel = unit?.unitTypeLabel as FlywheelTemplateUnitTypeLabelEnum;
    }

    return flywheelGoalTypeLabel;
  };

  /**
   * Create default subgoals if there isn't a new flywheel goal yet
   * Default to the current flywheel goal type and goals
   */
  const initialiseSubgoals = () => {
    let flywheelGoalTypeLabel = selectedUnit?.unitTypeLabel;

    // make sure we have the right typeLabel
    if (!flywheelGoalTypeLabel) {
      flywheelGoalTypeLabel = getFlywheelGoalTypeLabel();
    }

    // Get initial subgoals
    const subgoals = breakDownSubgoals({
      mainGoal: flywheelGoalInReview ? flywheelGoalInReview.goal : flywheel?.latestFlywheelGoal?.goal || "0", // default to the latest -> they can change this
      achieveByDate: newFlywheelGoalAchieveBy.toDate(), // new year should end exactly one year from the achieveBy date of the current goal
      timeframe: "QUARTERLY",
      typeLabel: (flywheelGoalTypeLabel || FlywheelTemplateUnitTypeLabelEnum.CURRENCY) as FlywheelTemplateUnitTypeLabelEnum
    });

    // If we already have a target for subgoal update it in subgoals
    const updatedSubgoals = updateFirstSubgoalTarget(subgoals);

    // Set them in state
    setNewSubgoals(updatedSubgoals);
  };

  const updateFirstSubgoalTarget = (subgoals: CreateSubgoalType[]) => {
    return subgoals.map((subgoal, idx) => idx === 0 ? {
      ...subgoal,
      goal: watchedSubgoal
    } : subgoal);
  };

  const handleSubgoalBreakdown = () => {
    // Get a subgoal breakdown based on the value of the main goal
    const subgoals = breakDownSubgoals({
      mainGoal: watchedGoal, // default to the latest -> they can change this
      achieveByDate: newFlywheelGoalAchieveBy.toDate(),
      timeframe: "QUARTERLY",
      typeLabel: (selectedUnit?.unitTypeLabel || getFlywheelGoalTypeLabel()) as FlywheelTemplateUnitTypeLabelEnum
    });

    // Update the first subgoal to match the subgoal target
    return updateFirstSubgoalTarget(subgoals);
  };

  const [ newSubgoals, setNewSubgoals ] = useState<CreateSubgoalType[]>([]);

  const canReviewMetrics = useMemo(() => isRodaAdmin || isAdmin || isMetricOwner, [
    isAdmin,
    isMetricOwner,
    isRodaAdmin
  ]);

  const unitIcon = useMemo(() => getUnitSymbol(selectedUnit?.unitTypeLabel, flywheel?.currency), [ flywheel?.currency, selectedUnit?.unitTypeLabel ]);
  // Permissions to review
  const hasAccessToReview = (isAdmin || isRodaAdmin || isFlywheelGoalOwner);
  const hasAccessToReviewNewYear = isAdmin || isRodaAdmin;
  // Check if we have rolled into the new year
  const twoWeeksInQuarter = dayjs(checkInSubgoal?.startDate).add(2, "weeks");
  const isAtStartOfQuarter = dayjs().isBetween(dayjs(checkInSubgoal?.startDate), twoWeeksInQuarter, null, "[]");
  const rolledIntoNewYear = isAtStartOfQuarter && isNextYearReview;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    clearErrors,
    formState: { errors }
  } = useForm<z.infer<typeof ReviewFlywheelGoalSchema>>({
    resolver: zodResolver(ReviewFlywheelGoalSchema),
    shouldFocusError: false,
    defaultValues: {
      name: flywheelGoalInReview ? flywheelGoalInReview.name : flywheel?.latestFlywheelGoal?.name || undefined,
      goal: flywheelGoalInReview ? flywheelGoalInReview.goal : flywheel?.latestFlywheelGoal?.goal || undefined,
      subgoal: subgoalInReview ? subgoalInReview.goal : flywheelSubgoals?.length ? flywheelSubgoals[ 0 ].goal : undefined // Default to the first subgoal if there's not a subgoal in review
    }
  });

  /**
   * Function for getting the flywheel template name by id
   */
  const getFlywheelTemplateName = (id: number) => {
    const name = availableFlywheelTemplates.find(i => +i.id === id)?.name || FlywheelGoalEnum.GROWTH;

    return name as FlywheelGoalEnum;
  };

  // Callback for selecting a flywheel goal unit based on its name
  const handleSelectUnit = useCallback((name: string) => {
    name && setValue("name", name);

    // Get the template name
    const flywheelTemplateName = getFlywheelTemplateName(flywheel?.flywheelTemplateId || 1);
    // Find the right unit object based on the name that was picked
    const unit = flywheelGoalUnits[ flywheelTemplateName ].find(unit => unit.name === name);

    setSelectedUnit(unit);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setValue ]);

  // Initialise the form state of name and state of selectedUnit
  useEffect(() => {
    const existingName = getValues("name");

    if (existingName) {
      handleSelectUnit(existingName);
    }

    // Initialise subgoals
    initialiseSubgoals();

    // Initialise subgoals
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ handleSelectUnit ]);

  // Watch fields
  const watchedGoal = watch("goal");
  const watchedSubgoal = watch("subgoal");

  // Get cap percentage
  const exceedsPercentageMax = useMemo(() => selectedUnit?.unitTypeLabel === FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE && selectedUnit.cap !== null ? +watchedGoal > selectedUnit.cap : false, [
    selectedUnit?.cap,
    selectedUnit?.unitTypeLabel,
    watchedGoal
  ]);

  // Navigate to the right place depending on user role
  const navigateToTheNextStep = () => {
    if (canReviewMetrics) {
      navigate(routes.reviewMetrics(currentCompany ? currentCompany.id : undefined));
    } else {
      setHideMainSideNav(false);
      navigate(routes.dashboard(currentCompany ? currentCompany.id : undefined));
    }
  };

  const onSubmit = handleSubmit(values => {
    // Check if they have changed
    const flywheelGoalChanged = Number(values.goal) !== Number(flywheelGoal?.goal);
    const subgoalChanged = Number(values.subgoal) !== Number(subgoalInReview?.goal);

    /**
     * If it's a next year review then we need to create or update flywheel goal with subgoals
     * before end of quarter -> need to create flywheel goal for next year
     * If we have created it already then subgoalInReview will be the first subgoal of the next year
     * If we have rolled into the new year -> we should have subgoalInReview as the first subgoal of the year (current subgoal)
     */
    if (isNextYearReview) {
      // Update the first subgoal to match the desired target
      const subs = handleSubgoalBreakdown();

      // Update the newSubgoals
      setNewSubgoals(subs);
      // If there's a flywheel goal in review then get its id, OTHERWISE we will create a new one
      const newYearFlywheelGoalId = flywheelGoalInReview ? +flywheelGoalInReview.id : undefined;
      // Find the fromDate of the first subgoal
      const fromDate = subgoalInReview ? subgoalInReview.startDate : newSubgoals[ 0 ].startDate;

      if (flywheel?.id && selectedUnit && user?.email) {
        // If there's already a flywheel goal in review then update current goal and subgoals
        if (newYearFlywheelGoalId !== undefined) {
          updateFlywheelGoalAndSubgoalsReq({
            id: newYearFlywheelGoalId,
            flywheelId: +flywheel.id,
            achieveBy: constructUtcDate(newFlywheelGoalAchieveBy),
            subgoals: subs,
            goal: values.goal,
            name: selectedUnit.name,
            unitName: selectedUnit.unitName,
            unitType: "number",
            unitTypeLabel: selectedUnit.unitTypeLabel,
            unitDisplay: selectedUnit.unitDisplay,
            unitDescription: selectedUnit.unitDescription,
            cap: selectedUnit.cap,
            updateFrequency: "MONTHLY", // Default to monthly - they will be able to change this in the next step
            fromDate
          }).then(() => {
            // Refetch the flywheel
            refetchFlywheel();
            navigateToTheNextStep();
          })
            .catch(e => {
              handleRodaError(e, "Failed to update new year's flywheel goal");
            });
        } else {
        // Otherwise create flywheel goal with subgoals
          createFlywheelGoalReq({
            flywheelGoalId: newYearFlywheelGoalId,
            flywheelId: +flywheel.id,
            achieveBy: constructUtcDate(newFlywheelGoalAchieveBy),
            subgoals: subs,
            goal: values.goal,
            name: selectedUnit.name,
            unitName: selectedUnit.unitName,
            unitType: "number",
            unitTypeLabel: selectedUnit.unitTypeLabel,
            unitDisplay: selectedUnit.unitDisplay,
            unitDescription: selectedUnit.unitDescription,
            cap: selectedUnit.cap,
            ownerEmail: flywheel?.latestFlywheelGoal?.owner?.email || user?.email,
            updateFrequency: "MONTHLY", // Default to monthly - they will be able to change this in the next step
            fromDate
          })
            .then(() => {
            // Refetch the flywheel
              refetchFlywheel();
              navigateToTheNextStep();
            })
            .catch(e => {
              handleRodaError(e, "Failed to create new flywheel goal");
            });
        }
      }
    } else {
      // Navigate to next step if nothing's changed
      if (!flywheelGoalChanged && !subgoalChanged) {
        navigateToTheNextStep();
      } else if (flywheelGoalChanged && (subgoalChanged && subgoalInReview)) {
      // If they both have changed then go to the next step when both have resolved
        Promise.all([
          updateFlywheelGoalReq({
            flywheelGoalId: Number(flywheelGoal?.id),
            goal: String(values.goal)
          }),
          updateSubgoalReq({
            subgoalId: Number(subgoalInReview.id),
            goal: String(values.subgoal)
          })
        ])
          .then(() => {
            navigateToTheNextStep();
          })
          .catch(e => {
            handleRodaError(e, "Something went wrong. Please try again.");
          });
      } else {
      // Else fire individual requests
        if (flywheelGoalChanged) {
          updateFlywheelGoalReq({
            flywheelGoalId: Number(flywheelGoal?.id),
            goal: String(values.goal)
          })
            .then(() => {
              navigateToTheNextStep();
            })
            .catch(e => {
              handleRodaError(e, "Couldn't update the main goal. Please try again.");
            });
        }

        if (subgoalChanged) {
          if (subgoalInReview) {
            updateSubgoalReq({
              subgoalId: Number(subgoalInReview.id),
              goal: String(values.subgoal)
            })
              .then(() => {
                navigateToTheNextStep();
              })
              .catch(e => {
                handleRodaError(e, "Couldn't update the target for next quarter. Please try again.");
              });
          }
        }
      }
    }
  });

  // On render of this page - always hide the main side nav
  useEffect(() => {
    setHideMainSideNav(true);
  }, [ setHideMainSideNav ]);

  useEffect(() => {
    // Check for different permissions if this is a Yearly review
    if (isNextYearReview ? !hasAccessToReviewNewYear : !hasAccessToReview) {
      navigate(routes.dashboard(currentCompany ? currentCompany.id : undefined));
    }
  }, [
    currentCompany,
    hasAccessToReview,
    hasAccessToReviewNewYear,
    isNextYearReview,
    navigate,
    rolledIntoNewYear
  ]);

  return (
    <>
      <ReviewContentWrapper
        title={activeFlywheelReview?.reviewPeriod === ReviewPeriod.NEXT_QUARTER ? "Review your goal" : rolledIntoNewYear ? "Review your goal for the new annual cycle" : "Set your goal for the next annual cycle"}
        subTitle={activeFlywheelReview?.reviewPeriod === ReviewPeriod.NEXT_QUARTER ? "Do you want to update your main flywheel goal before starting the next quarter?" : "Set your goal for the next annual cycle"}

        leftContent={(
          <div className="w-full flex flex-col">
            <form
              onSubmit={onSubmit}
              className="flex flex-col flex-1"
            >
              <div className="flex flex-col gap-y-3">
                {/** Only render in next year reviews */}
                {isNextYearReview && (
                  <SelectInput
                    value={watch("name")}
                    onValueChange={val => handleSelectUnit(val)}
                    label="How will you measure your goal?"
                    placeholder="*Pick a measure*"
                    options={flywheelGoalUnits[ getFlywheelTemplateName(flywheel?.flywheelTemplateId || 1) ].map(unit => unit.name)}
                    optionsDescriptions={flywheelGoalUnits[ getFlywheelTemplateName(flywheel?.flywheelTemplateId || 1) ].map(unit => unit.unitDescription)}
                    hasError={!!errors.name}
                    errorMessage={errors.name?.message}
                    clearFormError={() => clearErrors("name")}
                  />
                )}

                <Input
                  {...register("goal", {
                    onChange: e => {
                      const cleanedValue = cleanNumberInput(e.target.value);

                      setValue("goal", cleanedValue);
                    }
                  })}
                  label={isNextYearReview ? "What is the main goal for your next annual cycle?" : "Do you want to revise the main goal for the current annual cycle?"}
                  name="goal"
                  iconComponent={<p>{unitIcon}</p>}
                  iconPosition={unitIcon === "%" ? "right" : "left"}
                  min={0.01}
                  inputMode="numeric"
                  autoComplete="off"
                  autoCorrect="off"
                  hasError={!!errors.goal || exceedsPercentageMax}
                  errorMessage={exceedsPercentageMax ? `Max of ${selectedUnit?.cap}% reached` : errors.goal?.message}
                />

                <Input
                  {...register("subgoal", {
                    onChange: e => {
                      const cleanedValue = cleanNumberInput(e.target.value);

                      setValue("subgoal", cleanedValue);
                    }
                  })}
                  label={isNextYearReview ? "What is the target for your first quarter?" : "What is your target for the next quarter?"}
                  name="subgoal"
                  iconComponent={<p>{unitIcon}</p>}
                  iconPosition={unitIcon === "%" ? "right" : "left"}
                  min={0.01}
                  inputMode="numeric"
                  autoComplete="off"
                  autoCorrect="off"
                  hasError={!!errors.subgoal}
                  errorMessage={errors.subgoal?.message}
                />
              </div>
            </form>

            <div className={`flex gap-x-3 self-start pb-5 mt-5 ${isMobile ? "flex-col w-full gap-3" : "flex-row"}`}>
              <Button
                title="Back"
                className="bg-brand-cold-metal-200 text-brand-cold-metal-800"
                onClick={() => navigate(routes.review(currentCompany ? currentCompany.id : undefined))}
              />

              <Button
                title="Confirm goal"
                disabled={exceedsPercentageMax}
                loading={updateFlywheelGoalRes.fetching || updateSubgoalRes.fetching || createFlywheelGoalRes.fetching || updateFlywheelGoalAndSubgoalsRes.fetching}
                type="submit"
                onClick={onSubmit}
              />
            </div>
          </div>
        )}
        rightContent={isNextYearReview ? (
          <NextYearReviewGoalTimeline
            mainGoalValue={+watchedGoal}
            subgoalValue={+watchedSubgoal}
            rolledIntoNewYear={rolledIntoNewYear}
            subgoals={newSubgoals}
            unitIcon={unitIcon}
          />
        ) : subgoalInReview ? (
          <NextQuarterReviewGoalTimeline
            mainGoalValue={+watchedGoal}
            subgoalValue={+watchedSubgoal}
          />
        ) : undefined}
      />
    </>

  );
};