import { STORAGE_KEYS } from "~/constants/storageKeys";
import type { UserSession } from "~/hooks/session/use-get-session";

export const getFakeNowDate = () => {
  const isNotProduction = process.env.NODE_ENV !== "production";
  const userSession = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE.USER_SESSION);
  const parsedSession = userSession ? JSON.parse(userSession) as UserSession : null;
  let now = new Date(); // <- Use Date (), used in dayjs

  // if staging (and roda admins) or dev set now to currentDate from local storage
  if (isNotProduction) {
    now = new Date(parsedSession?.fakeNowDate);
  }

  return now;
};