import {
  enumCurrency,
  enumUserRole
} from "@roda/graphql/genql";
import {
  useEffect,
  useMemo,
  useRef
} from "react";
import { useNavigate } from "react-router-dom";

import { OnboardingContent } from "~/components/onboarding/OnboardingContent";
import type { OnboardingStep } from "~/components/onboarding/OnboardingProgressBar";
import { Loading } from "~/components/Spinner";
import { routes } from "~/constants/routes";
import { useCustomiseFlywheelGoalDispatch } from "~/contexts/CustomiseFlywheelGoalContext/CustomiseFlywheelGoalContext";
import {
  useOnboardingDispatch,
  useOnboardingState
} from "~/contexts/OnboardingContext/OnboardingContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useSideNavigation } from "~/contexts/SideNavigationContext";
import { useCurrentUser } from "~/contexts/UserContext";

import type { User } from "@roda/graphql/genql";

interface OnboardingProps {
  createFlywheelMode?: boolean;
  firstStep?: OnboardingStep;
}
export const Onboarding: React.FC<OnboardingProps> = ({ createFlywheelMode }) => {
  const { user, fetching: fetchingUsers } = useCurrentUser();

  const {
    step, flywheel: onboardingFlywheel, company: onboardingCompany, user: onboardingUser, invitedUsers
  } = useOnboardingState();

  const { flywheel, loading: fetchingFlywheels } = useSelectedFlywheel();
  const onboardingDispatch = useOnboardingDispatch();
  const flywheelGoalDispatch = useCustomiseFlywheelGoalDispatch();
  const navigate = useNavigate();
  const { setHideMainSideNav } = useSideNavigation();
  const hasNavigatedBecauseOfUserFetch = useRef(false);
  const hasNavigatedBecauseOfFlywheelFetch = useRef(false);
  // Make sure that onboarding flywheel has setupComplete as FALSE
  const flywheelToLoad = useMemo(() => !flywheel?.setupComplete ? flywheel : null, [ flywheel ]);

  // Initialise onboarding state using current user and flywheel context
  useEffect(() => {
    let navigateToStep: OnboardingStep = "user_details";
    const activeUsers = (user?.company?.activeUsers || []) as User[];

    // Hide main side nav if we're on createFlywheelMode
    if (createFlywheelMode) {
      setHideMainSideNav(true);
    }

    if (user?.role === enumUserRole.RODA_ADMIN) {
      navigate(routes.rodaAdminOrganisationManagement);
    }

    // Redirect to dashboard if onboarding is completed and !createFlywheelMode
    if (user?.company?.completedOnboarding && !createFlywheelMode) {
      navigate(routes.dashboard());
    }

    if (user?.firstName) {
      // user info setup so go to company!
      navigateToStep = "company_details";
    }

    if (user?.company?.name) {
      // company info setup so go to FW intro!
      navigateToStep = "flywheel_intro";
    }

    if (onboardingUser?.email || createFlywheelMode) {
      return;
    }

    if (!hasNavigatedBecauseOfUserFetch.current) {
      hasNavigatedBecauseOfUserFetch.current = true;

      onboardingDispatch({
        type: "SET_STATE",
        state: {
          step: navigateToStep,
          user: {
            firstName: user?.firstName ?? onboardingUser?.firstName ?? null,
            lastName: user?.lastName ?? onboardingUser?.lastName ?? null,
            email: user?.email ?? onboardingUser?.email ?? null,
            jobTitle: user?.jobTitle ?? onboardingUser?.jobTitle ?? null
          },
          // Always default to onboarding states for createFlywheelMode
          company: user?.company ? {
            id: +user?.company?.id,
            name: user?.company?.name,
            industryId: user?.company?.industryId,
            currency: onboardingCompany?.currency ?? enumCurrency.GBP
          } : onboardingCompany,
          invitedUsers: createFlywheelMode ? activeUsers?.map(user => user.email) : invitedUsers
        }
      });
    }
  }, [
    createFlywheelMode,
    onboardingDispatch,
    invitedUsers,
    navigate,
    onboardingCompany,
    onboardingUser,
    setHideMainSideNav,
    step,
    user
  ]);

  // Initialise onboarding flywheel using loaded-in flywheel, if present
  useEffect(() => {
    // No currency means the FW hasn't fully loaded - so we wait
    if (!flywheelToLoad?.currency) {
      return;
    }

    // We can return as we've already loaded this in!
    if (onboardingFlywheel?.id) {
      return;
    }

    if (!hasNavigatedBecauseOfFlywheelFetch.current) {
      hasNavigatedBecauseOfFlywheelFetch.current = true;

      if (flywheelToLoad) {
        onboardingDispatch({
          type: "LOAD",
          flywheel: flywheelToLoad
        });

        flywheelGoalDispatch({
          type: "SET_SELECTED_FLYWHEEL_TEMPLATE",
          flywheelTemplate: {
            name: flywheelToLoad?.name,
            id: flywheelToLoad?.flywheelTemplateId,
            steps: flywheelToLoad?.steps?.map((step, idx) => ({
              id: step.id,
              name: step.name,
              order: step.order || idx,
              description: "",
              metrics: step.metrics
            })) || []
          }
        });

        if (flywheelToLoad.latestFlywheelGoal) {
          flywheelGoalDispatch({
            type: "SET_SELECTED_FLYWHEEL_GOAL",
            flywheelGoal: {
              cap: flywheelToLoad.latestFlywheelGoal?.cap,
              unitDescription: flywheelToLoad.latestFlywheelGoal?.unitDescription,
              unitDisplay: flywheelToLoad.latestFlywheelGoal?.unitDisplay,
              unitName: flywheelToLoad.latestFlywheelGoal?.unitName,
              unitTypeLabel: flywheelToLoad.latestFlywheelGoal?.unitTypeLabel,
              name: flywheelToLoad.latestFlywheelGoal?.name
            }
          });
        }
      }
    }
  }, [
    flywheelGoalDispatch,
    step,
    onboardingFlywheel,
    flywheelToLoad,
    user,
    fetchingFlywheels,
    onboardingDispatch
  ]);

  // Re-direct out of onboarding if needed
  useEffect(() => {
    // Wait for the flywheels to load - any user without a company id should remain here
    if (!fetchingFlywheels) {
      // If completedOnboarding is true
      if (user?.company?.completedOnboarding && !createFlywheelMode) {
        navigate(routes.dashboard(), { replace: true });
        // If completedOnboarding is not complete but there's a flywheel
      } else if (!user?.company?.completedOnboarding && flywheel) {
        // If setupComplete is true re-direct to ready to start
        if (flywheel.setupComplete) {
          navigate(routes.readyToStart);
        }
      }
    }
  }, [
    createFlywheelMode,
    fetchingFlywheels,
    flywheel,
    navigate,
    user?.company?.completedOnboarding
  ]);

  // Change step function
  const changeStep = (step: OnboardingStep) => {
    onboardingDispatch({
      type: "SET_STEP",
      step
    });
  };

  if (!hasNavigatedBecauseOfFlywheelFetch.current && (fetchingUsers || fetchingFlywheels)) {
    return (
      <Loading.AbsoluteCenter />
    );
  }

  return (
    <div className="flex flex-col gap-2 flex-1">

      <OnboardingContent
        currentStep={step}
        changeStep={changeStep}
        createFlywheelMode={createFlywheelMode}
      />

    </div>
  );
};